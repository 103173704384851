import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
import Slider from "@material-ui/core/Slider";
import Button from "@material-ui/core/Button";
import { authFetch } from "../../auth";
import { useAppDispatch } from "../../redux/hooks";
import InputLabel from "@material-ui/core/InputLabel";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import Popover from "@material-ui/core/Popover";
import Select from "@material-ui/core/Select";
import AddIcon from "@material-ui/icons/Add";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { useAppSelector } from "../../redux/hooks";
import { getVulnScores } from "../../redux/reducers/vulnScoresSlice";
import { getVulnScores2 } from "../../redux/reducers/vulnScoresSlice";
import { getVulnScores3 } from "../../redux/reducers/vulnScoresSlice";
import { getUserRoutes } from "../../redux/reducers/userRoutesSlice";
import { useState, useEffect, useRef } from "react";
import { setBackdrop } from "../../redux/reducers/backdropSlice";
import { setVulnScores } from "../../redux/reducers/vulnScoresSlice";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";
import { setUserRoutes } from "../../redux/reducers/userRoutesSlice";
import EditIcon from "@material-ui/icons/Edit";
import Tooltip from "@material-ui/core/Tooltip";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Link from "@material-ui/core/Link";
import Avatar from "@material-ui/core/Avatar";
import BarChartIcon from "@material-ui/icons/BarChart";
import { setChart } from "../../redux/reducers/chartDisplaySlice";
import { setRoute1, getRoute1 } from "../../redux/reducers/selectedRoutesSlice";
import { setRoute2, getRoute2 } from "../../redux/reducers/selectedRoutesSlice";
import { setRoute3, getRoute3 } from "../../redux/reducers/selectedRoutesSlice";
import { getVisibility } from "../../redux/reducers/selectedRoutesSlice";
import {
  setVisibility2,
  getVisibility2,
} from "../../redux/reducers/selectedRoutesSlice";
import {
  setVisibility3,
  getVisibility3,
} from "../../redux/reducers/selectedRoutesSlice";
import {
  getRouteName,
  setRouteName,
  getRouteName2,
  setRouteName2,
  getRouteName3,
  setRouteName3,
  setRoute as setLoadedRoute,
  setRoute2 as setLoadedRoute2,
  setRoute3 as setLoadedRoute3,
  getRouteId,
  setRouteId,
} from "../../redux/reducers/uploadedRouteSlice";
import ShowChartIcon from "@material-ui/icons/ShowChart";
import {
  getBridgeOverpassSlider,
  getBridgeOverWaterSlider,
  getBridgeOverLandSlider,
  getCurvatureSlider,
  getPopulationRuralSlider,
  getPopulationSuburbanSlider,
  getPopulationUrbanSlider,
  getTunnelSlider,
  getRailSlider,
  setBridgeOverpassSlider,
  setBridgeOverWaterSlider,
  setBridgeOverLandSlider,
  setCurvatureSlider,
  setPopulationRuralSlider,
  setPopulationSuburbanSlider,
  setPopulationUrbanSlider,
  setTunnelSlider,
  setRailSlider,
  toggleBridgeOverLandVisibility,
  toggleBridgeOverpassVisibility,
  toggleBridgeOverWaterVisibility,
  toggleCurvatureVisibility,
  togglePopulationRuralVisibility,
  togglePopulationSuburbanVisibility,
  togglePopulationUrbanVisibility,
  toggleTunnelVisibility,
  toggleRailVisibility,
  getBridgeOverLandVisibility,
  getBridgeOverpassVisibility,
  getBridgeOverWaterVisibility,
  getCurvatureVisibility,
  getPopulationRuralVisibility,
  getPopulationSuburbanVisibility,
  getPopulationUrbanVisibility,
  getTunnelVisibility,
  getRailVisibility,
} from "../../redux/reducers/sliderSlice";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  getRoute as getLoadedRoute,
  getRoute2 as getLoadedRoute2,
  getRoute3 as getLoadedRoute3,
} from "../../redux/reducers/uploadedRouteSlice";
import { getDestinations } from "../../redux/reducers/destinationsSlice";
import GetAppIcon from "@material-ui/icons/GetApp";
import TestRoute from "../testRoutes/test_route_id-1.json";
import { withStyles } from "@material-ui/core/styles";
import TstarTab from "./TstarTab";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    // flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  list: {
    backgroundColor: theme.palette.background.paper,
    marginLeft: theme.spacing(11),
    marginRight: theme.spacing(11),
  },
  formControl: {
    margin: theme.spacing(3),
    alignItems: "center",
  },
  popover: {
    pointerEvents: "none",
    width: 350,
  },
  popover2: {
    pointerEvents: "none",
    width: 550,
  },
  paper: {
    padding: theme.spacing(1),
    width: 700,
    // height: 150,
  },
  popoverContent: {
    textAlign: "center",
  },
  dropdown: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(-0.5),
    minWidth: 120,
  },
  likert_image: {
    maxWidth: 300,
  },
  Mapbox_Traffic: {
    backgroundColor: "#06d6a0",
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
    marginRight: 5,
  },
  noCellAny: {
    backgroundColor: "#f94144",
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
    marginRight: 5,
  },
  noCellAtt: {
    backgroundColor: "#c44536",
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
    marginRight: 5,
  },
  noCellTmobile: {
    backgroundColor: "#f8961e",
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
    marginRight: 5,
  },
  noCellUsCellular: {
    backgroundColor: "#f9c74f",
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
    marginRight: 5,
  },
  noCellVerizon: {
    backgroundColor: "#90be6d",
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
    marginRight: 5,
  },
  NWS_Current_Warnings: {
    backgroundColor: "#43aa8b",
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
    marginRight: 5,
  },
  NWS_Current_Watches: {
    backgroundColor: "#004e98",
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
    marginRight: 5,
  },
  NWS_Wildfires: {
    backgroundColor: "#6a4c93",
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
    marginRight: 5,
  },
  hifldBridges: {
    backgroundColor: "#f94144",
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
    marginRight: 5,
  },
  hifldRailJunctions: {
    backgroundColor: "#6a4c93",
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
    marginRight: 5,
  },
  hifldTunnels: {
    backgroundColor: "#43aa8b",
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
    marginRight: 5,
  },
  hifldWeightStations: {
    backgroundColor: "#004e98",
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
    marginRight: 5,
  },
  hospitals: {
    // backgroundColor: '#502873',
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
    marginRight: 5,
  },
  routesList: {
    width: "100%",
    // maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  formControl2: {
    margin: theme.spacing(1),
    minWidth: 160,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  // paper2: {
  //   padding: theme.spacing(2),
  //   textAlign: 'center',
  //   color: theme.palette.text.secondary,
  // },
  button: {
    margin: theme.spacing(1),
  },
  margin: {
    margin: theme.spacing(1),
  },
  // list2 :{
  //   backgroundColor: theme.palette.background.paper,
  //   marginLeft : theme.spacing(8),
  //   marginRight: theme.spacing(8),
  // },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  root2: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    marginLeft: theme.spacing(12),
    marginRight: theme.spacing(12),
    border: "1px solid",
    // width: 400,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalPaper: {
    display: "inline-block",
    width: "25%",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(0, 6, 0),
  },
  LandScanUSAHover: {
    "&:hover": {
      // Define the styles for the hover state of the ListItem here
      // For example, change background color or add a box shadow
      backgroundColor: "#f0f0f0", // Change this to your desired hover background color
      boxShadow: "0 0 5px rgba(0, 0, 0, 0.3)", // Change this to your desired box shadow
    },
  },
  zoomTooltip: {
    fontSize: "14px",
  },
  normalText: {
    fontWeight: "normal", // Set fontWeight to normal
  },
}));

export default function SimpleTabs() {
  const [riskSliderValues, setRiskSliderValues] = useState(null); // Initialize with null or an appropriate initial value
  let bridgeOverLand = riskSliderValues?.["Bridge Over Land"];
  let bridgeOverWater = riskSliderValues?.["Bridge Over Water"];
  let bridgeOverpasses = riskSliderValues?.["Bridge Overpasses"];
  let bridgeTypeUknown = riskSliderValues?.["Bridge Type Unknown"];
  let curvature = riskSliderValues?.["Winding Narrow Roads"];
  let populationRural = riskSliderValues?.["Population: Rural"];
  let populationSuburban = riskSliderValues?.["Population: Suburban"];
  let populationUrban = riskSliderValues?.["Population: Urban"];
  let railroadClearanceUnknown =
    riskSliderValues?.["Railroad Clearance Unknown"];
  let railroadLowClearance = riskSliderValues?.["Railroad Low Clearance"];
  let railroadTypicalClearance =
    riskSliderValues?.["Railroad Typical Clearance"];
  let tunnelsSmallOrLarge = riskSliderValues?.["Tunnels (small or large)"];

  //mapping our slider green section to percentages
  const gradientMapping: Record<number, string> = {
    0: "0%",
    1: "0%",
    1.2: "2.7%", // Interpolated between 1 and 2
    2: "11.25%",
    2.8: "17.8125%", // Interpolated between 2 and 3
    3: "22.375%",
    3.3: "25.725%",
    3.7: "30.1125%", // Interpolated between 3.3 and 4
    4: "33.5%",
    4.3: "38.0625%", // Interpolated between 4 and 5
    4.7: "41.5625%", // Interpolated between 4 and 5
    5: "44.625%",
    6: "55.75%",
    7: "66.875%",
    8: "78%",
    9: "89%",
    9.1: "90.1%", // Interpolated between 9 and 10
    10: "100%",
  };

  const createCustomSlider = (railDataIndex: any, markDataIndex: any) => {
    // console.log(railDataIndex?.["mid_80"]);
    // console.log(gradientMapping[railDataIndex?.["mid_80"]]);
    return withStyles({
      root: {},
      thumb: {
        "&:focus, &:hover, &$active": {},
      },
      mark: {
        height: 10,
        width: 1,
        marginTop: -2,
        [`&[data-index="${markDataIndex}"]`]: {
          backgroundColor: "black",
          height: 20,
          width: 2,
          marginTop: -6,
          marginLeft: -1,
        },
      },
      active: {},
      rail: {
        height: 6,
        background: `linear-gradient(to right, black ${
          gradientMapping[0]
        }, black ${gradientMapping[railDataIndex?.["mid_20"] || 0]}, green ${
          gradientMapping[railDataIndex?.["mid_20"] || 0]
        }, green ${gradientMapping[railDataIndex?.["mid_80"] || 0]}, black ${
          gradientMapping[railDataIndex?.["mid_80"] || 0]
        }, black 100%)`,
      },
    })(Slider);
  };

  const BridgeOverpassesSlider = createCustomSlider(bridgeOverpasses, 2);
  const BridgeOverLandSlider = createCustomSlider(bridgeOverLand, 2);
  const BridgeOverWaterSlider = createCustomSlider(bridgeOverWater, 2);
  const BridgeTypeUnknownSlider = createCustomSlider(bridgeTypeUknown, 2);
  const CurvatureSlider = createCustomSlider(curvature, 5);
  const PopulationRuralSlider = createCustomSlider(populationRural, 2);
  const PopulationSuburbanSlider = createCustomSlider(populationSuburban, 3);
  const PopulationUrbanSlider = createCustomSlider(populationUrban, 5);
  const RailroadClearanceUnknownSlider = createCustomSlider(
    railroadClearanceUnknown,
    5
  );
  const RailroadLowClearanceSlider = createCustomSlider(
    railroadLowClearance,
    6
  );
  const RailroadTypicalClearanceSlider = createCustomSlider(
    railroadTypicalClearance,
    5
  );
  const TunnelsSmallOrLargeSlider = createCustomSlider(tunnelsSmallOrLarge, 5);

  const [infoStates, setInfoStates] = useState({
    BridgeOverpassesInfo: false,
    BridgeOverLandInfo: false,
    BridgeOverWaterInfo: false,
    BridgeTypeUnknownInfo: false,
    CurvatureInfo: false,
    PopulationRuralInfo: false,
    PopulationSuburbanInfo: false,
    PopulationUrbanInfo: false,
    RailroadClearanceUnknownInfo: false,
    RailroadLowClearanceInfo: false,
    RailroadTypicalClearanceInfo: false,
    TunnelsSmallOrLargeInfo: false,
    // Add more info states as needed
  });

  const toggleInfo = (infoName: any) => {
    setInfoStates((prevState: any) => ({
      ...prevState,
      [infoName]: !prevState[infoName],
    }));
  };

  const dispatch = useAppDispatch();

  const [showNewRouteButton, setShowNewRouteButton] = useState(true);

  const [view, setView] = React.useState<string | null>("default");
  const vulnScores = useAppSelector(getVulnScores);
  const vulnScores2 = useAppSelector(getVulnScores2);
  const vulnScores3 = useAppSelector(getVulnScores3);
  const route1Checked = useAppSelector(getRoute1);
  const route2Checked = useAppSelector(getRoute2);
  const route3Checked = useAppSelector(getRoute3);

  const bridgeOverpassSliderValue = useAppSelector(getBridgeOverpassSlider);
  const bridgeOverWaterSliderValue = useAppSelector(getBridgeOverWaterSlider);
  const bridgeOverLandSliderValue = useAppSelector(getBridgeOverLandSlider);
  const curvatureSliderValue = useAppSelector(getCurvatureSlider);
  const populationRuralSliderValue = useAppSelector(getPopulationRuralSlider);
  const populationSuburbanSliderValue = useAppSelector(
    getPopulationSuburbanSlider
  );
  const populationUrbanSliderValue = useAppSelector(getPopulationUrbanSlider);
  const tunnelSliderValue = useAppSelector(getTunnelSlider);
  const railSliderValue = useAppSelector(getRailSlider);

  const loadedRoute = useAppSelector(getLoadedRoute);
  const loadedRoute2 = useAppSelector(getLoadedRoute2);
  const loadedRoute3 = useAppSelector(getLoadedRoute3);

  const routeName = useAppSelector(getRouteName);
  const routeName2 = useAppSelector(getRouteName2);
  const routeName3 = useAppSelector(getRouteName3);

  const routeId = useAppSelector(getRouteId);

  let userRoutes: [{ id: ""; name: "" }];
  userRoutes = useAppSelector(getUserRoutes);
  let destinations = useAppSelector(getDestinations);

  let vulnScore = vulnScores.vulnScore;
  let delayScore = vulnScores.delayScore;
  let incidentScore = vulnScores.incidentScore;
  let popScore = vulnScores.popScore;
  let railScore = vulnScores.railJunctionScore;
  let outOfServiceAreaScore = vulnScores.outOfServiceAreaScore;
  let tunnelsScore = vulnScores.tunnelScore;
  let weatherScore = vulnScores.weatherScore;
  let wildfiresScore = vulnScores.wildfireScore;
  let cellCoverageScore = vulnScores.cellCoverageScore;
  let cellProvider = "Cell Coverage Score (" + vulnScores.cellProvider + "):";
  let mileage = vulnScores.mileage;
  let duration = vulnScores.duration;

  let vulnScore2 = vulnScores2.vulnScore;
  let delayScore2 = vulnScores2.delayScore;
  let incidentScore2 = vulnScores2.incidentScore;
  let popScore2 = vulnScores2.popScore;
  let railScore2 = vulnScores2.railJunctionScore;
  let outOfServiceAreaScore2 = vulnScores2.outOfServiceAreaScore;
  let tunnelsScore2 = vulnScores2.tunnelScore;
  let weatherScore2 = vulnScores2.weatherScore;
  let wildfiresScore2 = vulnScores2.wildfireScore;
  let cellCoverageScore2 = vulnScores2.cellCoverageScore;
  let cellProvider2 = "Cell Coverage Score (" + vulnScores2.cellProvider + "):";
  let mileage2 = vulnScores2.mileage;
  let duration2 = vulnScores2.duration;

  let vulnScore3 = vulnScores3.vulnScore;
  let delayScore3 = vulnScores3.delayScore;
  let incidentScore3 = vulnScores3.incidentScore;
  let popScore3 = vulnScores3.popScore;
  let railScore3 = vulnScores3.railJunctionScore;
  let outOfServiceAreaScore3 = vulnScores3.outOfServiceAreaScore;
  let tunnelsScore3 = vulnScores3.tunnelScore;
  let weatherScore3 = vulnScores3.weatherScore;
  let wildfiresScore3 = vulnScores3.wildfireScore;
  let cellCoverageScore3 = vulnScores3.cellCoverageScore;
  let cellProvider3 = "Cell Coverage Score (" + vulnScores3.cellProvider + "):";
  let mileage3 = vulnScores3.mileage;
  let duration3 = vulnScores3.duration;

  const visibilityState = useAppSelector(getVisibility);
  const visibilityState2 = useAppSelector(getVisibility2);
  const visibilityState3 = useAppSelector(getVisibility3);

  let bridgeOverLandVisibility = useAppSelector(getBridgeOverLandVisibility);
  let bridgeOverpassVisibility = useAppSelector(getBridgeOverpassVisibility);
  let bridgeOverWaterVisibility = useAppSelector(getBridgeOverWaterVisibility);
  let curvatureVisibility = useAppSelector(getCurvatureVisibility);
  let populationRuralVisibility = useAppSelector(getPopulationRuralVisibility);
  let populationSuburbanVisibility = useAppSelector(
    getPopulationSuburbanVisibility
  );
  let populationUrbanVisibility = useAppSelector(getPopulationUrbanVisibility);
  let tunnelVisibility = useAppSelector(getTunnelVisibility);
  let railVisibility = useAppSelector(getRailVisibility);

  let vulnScoreArray: any[] = [];
  vulnScoreArray.push(vulnScores.vulnScore);
  vulnScoreArray.push(vulnScores2.vulnScore);
  vulnScoreArray.push(vulnScores3.vulnScore);

  const toggleView = (
    event: React.MouseEvent<HTMLElement>,
    view: string | null
  ) => {
    setView(view);
    if (view == "normalized") {
      document.getElementById("normalizedRoute")!.click();
    }
    if (view == "default") {
      document.getElementById("defaultRoute")!.click();
    }
  };

  // const classes = useStyles();
  const [state, setState] = React.useState({
    age: "",
    name: "hai",
    Cell_Coverage2: true,
    Incidents: true,
    Population: true,
    Rail_Junctions: true,
    Safe_Havens: true,
    Tunnels: true,
    Weather: true,
    Wildfires: true,
    checkbox1: true,
    checkbox2: false,
    checkbox3: false,
    LandscanUSA: false,
    LandscanGlobal: false,
    Mapbox_Traffic: false,
    Cell_Coverage: false,
    Cell_Coverage_ATT: false,
    Cell_Coverage_T_Mobile: false,
    Cell_Coverage_US_Cellular: false,
    Cell_Coverage_Verizon: false,
    NWS_Current_Warnings: false,
    NWS_Current_Watches: false,
    NWS_Wildfires: false,
    NWS_Weather_Radar: false,
    HIFLD_Hospitals: false,
    HIFLD_Police: false,
    HIFLD_Weigh_Stations: false,
    HIFLD_EMS: false,
    HIFLD_Tunnels: false,
    HIFLD_Rail_Juntions: false,
    HIFLD_Bridges: false,
  });

  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const [openSave, setOpenSave] = React.useState(false);
  const [openSave2, setOpenSave2] = React.useState(false);
  const [openSave3, setOpenSave3] = React.useState(false);

  const handleOpen = (value: number) => {
    if (value == 0) {
      setOpen(true);
    }
    if (value == 1) {
      setOpen2(true);
    }
    if (value == 2) {
      setOpen3(true);
    }
    if (value == 3) {
      //if a route is actually loaded here
      if (loadedRoute.features[0].properties.id != 999999999999) {
        setOpenSave(true);
      }
    }
    if (value == 4) {
      if (loadedRoute2.features[0].properties.route_id) {
        setOpenSave2(true);
      }
    }
    if (value == 5) {
      if (loadedRoute3.features[0].properties.route_id) {
        setOpenSave3(true);
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
    setOpen2(false);
    setOpen3(false);
    setOpenSave(false);
    setOpenSave2(false);
    setOpenSave3(false);
  };

  function formatTime(hours: number): string {
    const days = Math.floor(hours / 24);
    const remainingHours = Math.floor(hours % 24);
    const minutes = Math.floor((hours * 60) % 60);

    const dayString = days > 0 ? `${days} day${days > 1 ? "s" : ""}` : "";
    const hourString =
      remainingHours > 0
        ? `${remainingHours} hour${remainingHours > 1 ? "s" : ""}`
        : "";
    const minuteString =
      minutes > 0 ? `${minutes} minute${minutes > 1 ? "s" : ""}` : "";

    const parts = [dayString, hourString, minuteString].filter(
      (part) => part !== ""
    );
    return parts.join(" ") || "0 hours";
  }

  function download(filename: string, text: string): void {
    filename = filename + ".geojson";
    const element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," + encodeURIComponent(text)
    );
    element.setAttribute("download", filename);

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  const exportRoute = (routeId: any) => {
    if (routeId == 1) {
      if (loadedRoute.features[0].properties.id != 999999999999) {
        download(routeName, JSON.stringify(loadedRoute));
      }
    }
    if (routeId == 2) {
      if (loadedRoute.features[0].properties.route_id) {
        download(routeName2, JSON.stringify(loadedRoute2));
      }
    }
    if (routeId == 3) {
      download(routeName3, JSON.stringify(loadedRoute3));
    }
  };

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const handleOpenDeleteDialog = () => {
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const handleDeleteRoute = () => {
    // Your delete logic here
    deleteRoute();
    setOpenDeleteDialog(false);
  };

  const deleteRoute = () => {
    setRouteToLoad(null);
    dispatch(setLoadedRoute(TestRoute));
    dispatch(setLoadedRoute2(TestRoute));
    dispatch(setLoadedRoute3(TestRoute));
    dispatch(
      setVulnScores({
        vulnScore: 0,
        cellCoverageScore: 0,
        delayScore: 0,
        popScore: 0,
        weatherScore: 0,
        incidentScore: 0,
        wildfireScore: 0,
        railJunctionScore: 0,
        tunnelScore: 0,
        outOfServiceAreaScore: 0,
        cellProvider: "Any",
        mileage: 0,
        duration: 0,
      })
    );
    document.getElementById("route1Toggle")!.click();
    dispatch(setRouteName(null));
    dispatch(setRouteName2(null));
    dispatch(setRouteName3(null));

    // let routeId = loadedRoute.features[0].properties.route_id;
    let body = {
      routeId: routeId,
    };
    console.log(body);

    authFetch("/delete_route", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }).then((res) => {
      console.log(res);
      let response = res.json();
      response.then(function (result: any) {
        authFetch("/user_routes", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }).then((res) => {
          console.log(res);
          let response = res.json();
          response.then(function (result: any) {
            console.log(result);
            dispatch(setUserRoutes(result));
            handleClose();
          });
        });
      });
    });
  };

  const saveRoute = () => {
    let name = (document.getElementById("routename") as HTMLInputElement).value;
    if (name !== "") {
      let weights = {
        delaysWeight: 0,
        cellProvider: "any",
        bridgeOverpassWeight: bridgeOverpassSliderValue,
        bridgeOverWaterWeight: bridgeOverWaterSliderValue,
        bridgeOverLandWeight: bridgeOverLandSliderValue,
        curvatureWeight: curvatureSliderValue,
        populationRuralWeight: populationRuralSliderValue,
        populationSuburbanWeight: populationSuburbanSliderValue,
        populationUrbanWeight: populationUrbanSliderValue,
        tunnelWeight: tunnelSliderValue,
        railWeight: railSliderValue,
      };
      // let routeId = loadedRoute.features[0].properties.route_id;
      // console.log(loadedRoute)
      console.log(name, weights, routeId, 1);
      submitSaveRequest(name, weights, routeId, 1);
      handleClose();
      dispatch(setBackdrop({ status: "true", message: "Saving your route" }));
    } else {
      alert("Please enter a name to save the route");
    }
  };

  const saveRoute2 = () => {
    let name = (document.getElementById("routename2") as HTMLInputElement)
      .value;
    if (name !== "") {
      let weights = {
        delaysWeight: 0,
        cellProvider: "any",
        bridgeOverpassWeight: bridgeOverpassSliderValue,
        bridgeOverWaterWeight: bridgeOverWaterSliderValue,
        bridgeOverLandWeight: bridgeOverLandSliderValue,
        curvatureWeight: curvatureSliderValue,
        tunnelWeight: tunnelSliderValue,
        railWeight: railSliderValue,
      };
      let routeId = loadedRoute.features[0].properties.route_id + 1;
      submitSaveRequest(name, weights, routeId, 2);
    } else {
      alert("Please enter a name to save the route");
    }
  };

  const saveRoute3 = () => {
    let name = (document.getElementById("routename3") as HTMLInputElement)
      .value;
    if (name !== "") {
      let weights = {
        delaysWeight: 0,
        cellProvider: "any",
        bridgeOverpassWeight: bridgeOverpassSliderValue,
        bridgeOverWaterWeight: bridgeOverWaterSliderValue,
        bridgeOverLandWeight: bridgeOverLandSliderValue,
        curvatureWeight: curvatureSliderValue,
        tunnelWeight: tunnelSliderValue,
        railWeight: railSliderValue,
      };
      let routeId = loadedRoute.features[0].properties.route_id + 2;
      submitSaveRequest(name, weights, routeId, 3);
    } else {
      alert("Please enter a name to save the route");
    }
  };

  const submitSaveRequest = (
    name: any,
    weights: any,
    routeId: any,
    routeNumber: any
  ) => {
    // Call Alec's save endpoint. Pass id, weights, name
    let body = {
      routeId: routeId,
      name: name,
      // "weights": weights
    };
    console.log(body);

    authFetch("/update_nickname", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }).then((res) => {
      console.log(res);
      let response = res.json();
      response.then(function (result: any) {
        //If route is saved, we'll need to refresh the user's list of routes
        //Maybe the return of this endpoint could give all saved routes for that user?
        authFetch("/user_routes", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }).then((res) => {
          console.log(res);
          let response = res.json();
          response.then(function (result: any) {
            console.log(result);
            dispatch(setUserRoutes(result));
            handleClose();
            switch (routeNumber) {
              case 1:
                dispatch(setRouteName(name));
                break;
              case 2:
                dispatch(setRouteName2(name));
                break;
              case 3:
                dispatch(setRouteName3(name));
                break;
            }
          });
          dispatch(setBackdrop({ status: "false" }));
        });
      });
    });
    dispatch(setBackdrop({ status: "false" }));
  };

  const loadRoute = (value: { id: ""; name: "" } | null | undefined) => {
    let routeId = 0;
    let name = "";
    console.log(value);
    if (value != null) {
      routeId = parseInt(value.id);
      name = value.name;
    }
    console.log(routeId);

    if (routeId != 0) {
      dispatch(setBackdrop({ status: "true" }));
      dispatch(setRouteId(routeId));
      if (document.getElementById("loadRoute")) {
        (document.getElementById("loadRoute") as HTMLInputElement).value =
          routeId.toString();
        (document.getElementById("loadRoute") as HTMLInputElement).name =
          name.toString();
        document.getElementById("loadRoute")!.click();
        dispatch(setVisibility2(false));
        dispatch(setVisibility3(false));
      }
    }
  };

  const {
    checkbox1,
    checkbox2,
    checkbox3,
    LandscanUSA,
    LandscanGlobal,
    Mapbox_Traffic,
    Cell_Coverage,
    Cell_Coverage_ATT,
    Cell_Coverage_T_Mobile,
    Cell_Coverage_US_Cellular,
    Cell_Coverage_Verizon,
    NWS_Current_Warnings,
    NWS_Current_Watches,
    NWS_Wildfires,
    NWS_Weather_Radar,
    HIFLD_Hospitals,
    HIFLD_Police,
    HIFLD_Weigh_Stations,
    HIFLD_EMS,
    HIFLD_Tunnels,
    HIFLD_Rail_Juntions,
    HIFLD_Bridges,
  } = state;

  const handleCheckboxChange2 = (event: any) => {
    setState({ ...state, [event.target.name]: event.target.checked });
    if (event.target.name == "checkbox1") {
      document.getElementById("route1Toggle")!.click();
      // document.getElementById('normalizedRoute1Toggle')!.click();
      dispatch(setRoute1(event.target.checked));
    }
    if (event.target.name == "checkbox2") {
      document.getElementById("route2Toggle")!.click();
      // document.getElementById('normalizedRoute2Toggle')!.click();
      dispatch(setRoute2(event.target.checked));
    }
    if (event.target.name == "checkbox3") {
      document.getElementById("route3Toggle")!.click();
      // document.getElementById('normalizedRoute3Toggle')!.click();
      dispatch(setRoute3(event.target.checked));
    }
  };

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // document.getElementById(event.target.name)!.click();
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  const {
    Cell_Coverage2,
    Incidents,
    Population,
    Rail_Junctions,
    Safe_Havens,
    Tunnels,
    Weather,
    Wildfires,
  } = state;

  const zoomToBounds = () => {
    document.getElementById("zoomToBounds")!.click();
  };

  // const generateRoute = () => {
  //   document.getElementById("clearMarkersBtn")!.click();
  //   document.getElementById("generateRoute")!.click();

  //   dispatch(setCellCoverageSlider(5));
  //   dispatch(setIncidentsSlider(5));
  //   dispatch(setPopulationSlider(5));
  //   dispatch(setRailJunctionsSlider(5));
  //   dispatch(setSafeHavensSlider(5));
  //   dispatch(setTunnelsSlider(5));
  //   dispatch(setWeatherSlider(5));
  //   dispatch(setWildfiresSlider(5));

  //   //I think we may need to move this to redux?
  //   // setVisibilityState2(true);
  //   // setVisibilityState3(true);
  // };

  const setLocationSelection = (location: string) => {
    document.getElementById(location)!.click();
  };

  const showRouteControls = () => {
    setShowNewRouteButton(false); // Hides the button

    const geocoderContainers = document.querySelectorAll(
      ".mapboxgl-ctrl-geocoder"
    ) as NodeListOf<HTMLElement>;
    const generateRouteContainer = document.querySelector(
      ".generate-route-controls"
    ) as HTMLElement;

    // Make elements visible
    geocoderContainers.forEach((container) => {
      if (container) container.style.visibility = "visible";
    });
    if (generateRouteContainer) {
      generateRouteContainer.style.visibility = "visible";
    }

    // Add the emphasis effect
    geocoderContainers.forEach((container) => {
      if (container) container.classList.add("emphasized");
    });
    if (generateRouteContainer) {
      generateRouteContainer.classList.add("emphasized");
    }

    // Remove the emphasis after 3 seconds
    setTimeout(() => {
      geocoderContainers.forEach((container) => {
        if (container) container.classList.remove("emphasized");
      });
      if (generateRouteContainer) {
        generateRouteContainer.classList.remove("emphasized");
      }
    }, 3000); // 3-second emphasis
  };

  const resetRouteCheckbox = () => {
    console.log("RESET ROUTE CHECKBOX");
    // setState(prevState => {
    //   return {
    //     ...prevState,
    //     checkbox1: false
    //   };
    // });
    setState((prevState) => {
      return {
        ...prevState,
        checkbox2: false,
      };
    });
    setState((prevState) => {
      return {
        ...prevState,
        checkbox3: false,
      };
    });
  };

  const [routeToLoad, setRouteToLoad] = React.useState<
    { id: ""; name: "" } | null | undefined
  >();
  const [origin, setOrigin] = React.useState<
    { id: ""; name: "" } | null | undefined
  >();
  const [destination, setDestination] = React.useState<
    { id: ""; name: "" } | null | undefined
  >();

  const userActionRef = useRef(false);

  useEffect(() => {
    if (!userActionRef.current) {
      console.log(routeToLoad, "- Has changed");
      loadRoute(routeToLoad);
    } else {
      userActionRef.current = false; // Reset after handling the user-triggered change
    }
  }, [routeToLoad]); // Runs when `routeToLoad` changes

  useEffect(() => {
    // Ensure that the selected value is updated when userRoutes change
    if (routeToLoad) {
      const matchingRoute = userRoutes.find(
        (route) => route.id === routeToLoad.id
      );
      if (matchingRoute && routeToLoad.name !== matchingRoute.name) {
        // Indicate this change is not from user action
        userActionRef.current = true;
        // Update the state
        setRouteToLoad((prevRoute: any) => ({
          ...prevRoute,
          name: matchingRoute.name,
        }));
      }
    }
  }, [userRoutes]); // Runs when `userRoutes` changes

  useEffect(() => {
    fetch("./risk_stats")
      .then((response) => response.json())
      .then((data) => {
        setRiskSliderValues(data);
      });
  }, []);

  // if (riskSliderValues) {
  //   console.log(riskSliderValues);
  // }

  interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index: any) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const congestionSlider = 30;

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
    if (newValue == 0) {
    }
  };

  const marks = [
    {
      value: 1,
      label: "1",
    },
    {
      value: 2,
      label: "2",
    },
    {
      value: 3,
      label: "3",
    },
    {
      value: 4,
      label: "4",
    },
    {
      value: 5,
      label: "5",
    },
    {
      value: 6,
      label: "6",
    },
    {
      value: 7,
      label: "7",
    },
    {
      value: 8,
      label: "8",
    },
    {
      value: 9,
      label: "9",
    },
    {
      value: 10,
      label: "10",
    },
  ];

  const [dropdownState, setDropdownState] = React.useState<{
    provider: string | number;
    name: string;
  }>({
    provider: "any",
    name: "any",
  });

  const handleDropdownChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const name = event.target.name as keyof typeof state;
    console.log(event.target.value);
    setDropdownState({
      ...dropdownState,
      [name]: event.target.value,
    });
  };

  const resetLayerSwitches = () => {
    setState((prevState) => {
      return {
        ...prevState,
        LandscanUSA: false,
      };
    });
    setState((prevState) => {
      return {
        ...prevState,
        LandscanGlobal: false,
      };
    });
    setState((prevState) => {
      return {
        ...prevState,
        Mapbox_Traffic: false,
      };
    });
    setState((prevState) => {
      return {
        ...prevState,
        Cell_Coverage: false,
      };
    });
    setState((prevState) => {
      return {
        ...prevState,
        Cell_Coverage_ATT: false,
      };
    });
    setState((prevState) => {
      return {
        ...prevState,
        Cell_Coverage_T_Mobile: false,
      };
    });
    setState((prevState) => {
      return {
        ...prevState,
        Cell_Coverage_US_Cellular: false,
      };
    });
    setState((prevState) => {
      return {
        ...prevState,
        Cell_Coverage_Verizon: false,
      };
    });
    setState((prevState) => {
      return {
        ...prevState,
        NWS_Current_Warnings: false,
      };
    });
    setState((prevState) => {
      return {
        ...prevState,
        NWS_Current_Watches: false,
      };
    });
    setState((prevState) => {
      return {
        ...prevState,
        NWS_Wildfires: false,
      };
    });
    setState((prevState) => {
      return {
        ...prevState,
        NWS_Weather_Radar: false,
      };
    });
    setState((prevState) => {
      return {
        ...prevState,
        HIFLD_Hospitals: false,
      };
    });
    setState((prevState) => {
      return {
        ...prevState,
        HIFLD_Police: false,
      };
    });
    setState((prevState) => {
      return {
        ...prevState,
        HIFLD_Weigh_Stations: false,
      };
    });
    setState((prevState) => {
      return {
        ...prevState,
        HIFLD_EMS: false,
      };
    });
    setState((prevState) => {
      return {
        ...prevState,
        HIFLD_Tunnels: false,
      };
    });
    setState((prevState) => {
      return {
        ...prevState,
        HIFLD_Rail_Juntions: false,
      };
    });
    setState((prevState) => {
      return {
        ...prevState,
        HIFLD_Bridges: false,
      };
    });
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    document.getElementById(event.target.name)!.click();
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  // const changeCellCoverageSlider = (event: any, value: any) => {
  //   dispatch(setCellCoverageSlider(value));
  // };

  // const changeIncidentsSlider = (event: any, value: any) => {
  //   dispatch(setIncidentsSlider(value));
  // };

  // const changePopulationSlider = (event: any, value: any) => {
  //   dispatch(setPopulationSlider(value));
  // };

  // const changeRailJunctionsSlider = (event: any, value: any) => {
  //   dispatch(setRailJunctionsSlider(value));
  // };

  // const changeSafeHavensSlider = (event: any, value: any) => {
  //   dispatch(setSafeHavensSlider(value));
  // };

  // const changeTunnelsSlider = (event: any, value: any) => {
  //   dispatch(setTunnelsSlider(value));
  // };

  // const changeWeatherSlider = (event: any, value: any) => {
  //   dispatch(setWeatherSlider(value));
  // };

  // const changeWildfiresSlider = (event: any, value: any) => {
  //   dispatch(setWildfiresSlider(value));
  // };

  const changeBridgeOverpassSlider = (event: any, value: any) => {
    dispatch(setBridgeOverpassSlider(value));
  };

  const changeBridgeOverWaterSlider = (event: any, value: any) => {
    dispatch(setBridgeOverWaterSlider(value));
  };

  const changeBridgeOverLandSlider = (event: any, value: any) => {
    dispatch(setBridgeOverLandSlider(value));
  };

  const changeCurvatureSlider = (event: any, value: any) => {
    dispatch(setCurvatureSlider(value));
  };

  const changePopulationRuralSlider = (event: any, value: any) => {
    dispatch(setPopulationRuralSlider(value));
  };

  const changePopulationSuburbanSlider = (event: any, value: any) => {
    dispatch(setPopulationSuburbanSlider(value));
  };

  const changePopulationUrbanSlider = (event: any, value: any) => {
    dispatch(setPopulationUrbanSlider(value));
  };

  const changeTunnelSlider = (event: any, value: any) => {
    dispatch(setTunnelSlider(value));
  };

  const changeRailSlider = (event: any, value: any) => {
    dispatch(setRailSlider(value));
  };

  //slider
  function valuetext(value: number) {
    // console.log(value);
    return `${value}°C`;
  }

  //popover stuff
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [popoverPosition, setPopoverPosition] = useState({
    top: 200,
    left: 500,
  }); // Manually set position

  const handlePopoverOpen = () => {
    setPopoverOpen(true);
  };

  const handlePopoverClose = () => {
    setPopoverOpen(false);
  };

  const [baseLayer, setBaseLayer] = React.useState<string | null>("Streets");

  const handleBaseLayerChange = (
    event: React.MouseEvent<HTMLElement>,
    newBaseLayer: string
  ) => {
    setBaseLayer(newBaseLayer);
    resetLayerSwitches();
    document.getElementById(newBaseLayer)!.click();
  };

  function runVulnerabilityCalc() {
    document.getElementById("vulnCalc")!.click();
  }

  function toggleChartView(value: any) {
    dispatch(setChart(value));
  }

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          <Tab label="Routes" {...a11yProps(0)} />
          <Tab label="Map Layers" {...a11yProps(1)} />
          <Tab label="Risk Controls" {...a11yProps(2)} />
          {/* <Tab label="T-STAR Live Data" {...a11yProps(3)} /> */}
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        {/*<RoutesTab/>*/}
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={6}
            container
            justifyContent="flex-start"
            alignItems="center"
          >
            <Autocomplete
              value={routeToLoad}
              onChange={(
                event: any,
                newValue: { id: ""; name: "" } | null | undefined
              ) => {
                setRouteToLoad(newValue);
              }}
              id="combo-box-demo"
              options={userRoutes}
              getOptionLabel={(option) => option!.name}
              style={{ width: "100%", marginTop: 10 }} // Change width to 100% for equal spacing
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Route"
                  variant="outlined"
                />
              )}
            />
            <FormControl className={classes.formControl2}></FormControl>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            container
            justifyContent="center"
            alignItems="center"
          >
            {loadedRoute.features[0].properties.id != 999999999999 && (
              <>
                <IconButton
                  onClick={zoomToBounds}
                  aria-label="save"
                  className={classes.margin}
                >
                  <ZoomInIcon fontSize="small" />
                </IconButton>
                <IconButton
                  aria-label="edit"
                  className={classes.margin}
                  onClick={() => handleOpen(3)}
                >
                  <EditIcon fontSize="small" />
                </IconButton>
                <IconButton
                  aria-label="delete"
                  className={classes.margin}
                  onClick={handleOpenDeleteDialog}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </>
            )}

            <Dialog
              open={openDeleteDialog}
              onClose={handleCloseDeleteDialog}
              aria-labelledby="delete-dialog-title"
            >
              <DialogTitle id="delete-dialog-title">Delete Route</DialogTitle>
              <DialogContent>
                Are you sure you want to permanently delete this route?
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDeleteDialog} color="primary">
                  Cancel
                </Button>
                <Button onClick={handleDeleteRoute} color="secondary">
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            container
            justifyContent="flex-start"
            alignItems="center"
          >
            {showNewRouteButton && (
              <Button
                variant="outlined"
                id="newRouteButton"
                onClick={() => showRouteControls()}
                startIcon={<AddIcon fontSize="small" />}
                size="small"
                className={classes.normalText}
              >
                New Route
              </Button>
            )}
          </Grid>

          {/* <Grid item xs={12} sm={4}>
            <InputLabel>Select View</InputLabel>
            <ToggleButtonGroup
              value={view}
              size="small"
              exclusive
              onChange={toggleView}
              aria-label="view"
              id="view"
            >
              <ToggleButton value="default" aria-label="Default">
                Default
              </ToggleButton>
              <ToggleButton value="normalized" aria-label="Normalized">
                Normalized
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid> */}
          {/* <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br> */}
        </Grid>
        {/*<Paper component="form">*/}
        {/* <Grid
          container
          spacing={1}
          direction="row"
          justifyContent="center"
          alignItems="center"
        > */}
        {/* <Grid item xs={9}> */}
        {/* <div id="geocoder"></div>
          <br></br>
          <div id="geocoder2"></div> */}
        {/* <Autocomplete
              value={origin}
              freeSolo
              // onChange={(event: any, newValue: { id: ""; name: ""; } | null | undefined)  => {
              //   // setValue2(newValue);
              // }}
              id="start-location"
              options={destinations}
              getOptionLabel={(option) => option.name}
              style={{ marginTop: 10, display: "inline" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Starting Location"
                  variant="outlined"
                />
              )}
              // onChange={handleChange}
            /> */}
        {/* </Grid> */}
        {/* <Grid item xs={1}>
            <Tooltip
              title="Click on the map to select a starting location"
              placement="right"
            >
              <div>
                <IconButton
                  color="primary"
                  className={classes.iconButton}
                  onClick={() => setLocationSelection("startLocation")}
                >
                  <AddLocationIcon />
                </IconButton>
              </div>
            </Tooltip>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={1}
          direction="row"
          justifyContent="center"
          alignItems="center"
        > */}
        {/* <Grid item xs={9}>
            <Autocomplete
              value={destination}
              freeSolo
              // onChange={(event: any, newValue: { id: ""; name: ""; } | null | undefined)  => {
              //   // setValue2(newValue);
              // }}
              id="destination"
              options={destinations}
              getOptionLabel={(option) => option.name}
              style={{ marginTop: 10, display: "inline" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Destination"
                  variant="outlined"
                />
              )}
              // onChange={handleChange}
            />
          </Grid> */}
        {/* <Grid item xs={1}>
            <Tooltip
              title="Click on the map to select a destination"
              placement="right"
            >
              <div>
                <IconButton
                  color="primary"
                  className={classes.iconButton}
                  onClick={() => setLocationSelection("destinationBtn")}
                >
                  <AddLocationIcon />
                </IconButton>
              </div>
            </Tooltip>
          </Grid> */}
        {/* </Grid> */}
        <br></br>
        {/* <Button
          variant="contained"
          id="runVulBtn"
          color="primary"
          onClick={() => generateRoute()}
        >
          Generate Route
        </Button> */}
        {/* <br></br>
        <br></br>
        <br></br> */}
        {/* <Divider /> */}
        <br></br>
        <List className={classes.routesList}>
          {loadedRoute.features[0].properties.id != 999999999999 && (
            <Grid container spacing={3}>
              <ListItem
                id="route1ListItem"
                key={0}
                role={undefined}
                dense
                button
                style={{ visibility: visibilityState ? "visible" : "hidden" }}
              >
                <ListItemIcon>
                  <Checkbox
                    id="checkbox1"
                    name="checkbox1"
                    edge="start"
                    checked={route1Checked}
                    tabIndex={-1}
                    disableRipple
                    onClick={handleCheckboxChange2}
                  />
                </ListItemIcon>
                <Grid item xs>
                  {routeName}
                </Grid>
                {/* <Grid item xs={4} style={{ paddingLeft: 10 }}>
                Average: {vulnScoreArray[0]}
              </Grid> */}
                {/* <Grid item xs={3}>
                <Link
                  style={{ paddingRight: 10 }}
                  onClick={() => handleOpen(0)}
                >
                  View Summary
                </Link>
              </Grid> */}
                <IconButton
                  onClick={() => toggleChartView(0)}
                  edge="end"
                  aria-label="comments"
                  style={{ paddingLeft: 10 }}
                >
                  <ShowChartIcon />
                </IconButton>
                <IconButton
                  onClick={() => toggleChartView(1)}
                  edge="end"
                  aria-label="comments"
                  style={{ paddingLeft: 10 }}
                >
                  <BarChartIcon />
                </IconButton>
                <IconButton
                  edge="end"
                  aria-label="show-hide"
                  onClick={() => handleOpen(3)}
                >
                  <SaveIcon />
                </IconButton>
                <IconButton
                  edge="end"
                  aria-label="show-hide"
                  onClick={() => exportRoute(1)}
                >
                  <GetAppIcon />
                </IconButton>
                {/*<IconButton edge="end" aria-label="show-hide">
              <VisibilityIcon />
            </IconButton>*/}
              </ListItem>
              {/* <ListItem
              id="route2ListItem"
              key={0}
              role={undefined}
              dense
              button
              style={{ visibility: visibilityState2 ? "visible" : "hidden" }}
            >
              <ListItemIcon>
                <Checkbox
                  id="checkbox2"
                  name="checkbox2"
                  edge="start"
                  checked={route2Checked}
                  tabIndex={-1}
                  disableRipple
                  onClick={handleCheckboxChange2}
                />
              </ListItemIcon>
              <Grid item xs>
                {routeName2}
              </Grid>
              <Grid item xs={3} style={{ paddingLeft: 10 }}>
                Average: {vulnScoreArray[1]}
              </Grid>
              <Grid item xs={3}>
                <Link
                  style={{ paddingRight: 10 }}
                  onClick={() => handleOpen(1)}
                >
                  View Summary
                </Link>
              </Grid>
              <IconButton
                onClick={() => toggleChartView(0)}
                edge="end"
                aria-label="comments"
                style={{ paddingLeft: 10 }}
              >
                <ShowChartIcon />
              </IconButton>
              <IconButton
                onClick={() => toggleChartView(2)}
                edge="end"
                aria-label="comments"
                style={{ paddingLeft: 10 }}
              >
                <BarChartIcon />
              </IconButton>
              <IconButton
                edge="end"
                aria-label="show-hide"
                onClick={() => handleOpen(4)}
              >
                <SaveIcon />
              </IconButton>
              <IconButton
                edge="end"
                aria-label="show-hide"
                onClick={() => exportRoute(2)}
              >
                <GetAppIcon />
              </IconButton>
              {/*<IconButton edge="end" aria-label="show-hide">
              <VisibilityIcon />
            </IconButton>*/}
              {/* //</ListItem> */}
              {/* <ListItem
              id="route3ListItem"
              key={0}
              role={undefined}
              dense
              button
              style={{ visibility: visibilityState3 ? "visible" : "hidden" }}
            >
              <ListItemIcon>
                <Checkbox
                  id="checkbox3"
                  name="checkbox3"
                  edge="start"
                  checked={route3Checked}
                  tabIndex={-1}
                  disableRipple
                  onClick={handleCheckboxChange2}
                />
              </ListItemIcon>
              <Grid item xs>
                {routeName3}
              </Grid>
              <Grid item xs={3} style={{ paddingLeft: 10 }}>
                Average: {vulnScoreArray[2]}
              </Grid>
              <Grid item xs={3}>
                <Link
                  style={{ paddingRight: 10 }}
                  onClick={() => handleOpen(2)}
                >
                  View Summary
                </Link>
              </Grid>
              <IconButton
                onClick={() => toggleChartView(0)}
                edge="end"
                aria-label="comments"
                style={{ paddingLeft: 10 }}
              >
                <ShowChartIcon />
              </IconButton>
              <IconButton
                onClick={() => toggleChartView(3)}
                edge="end"
                aria-label="comments"
                style={{ paddingLeft: 10 }}
              >
                <BarChartIcon />
              </IconButton>
              <IconButton
                edge="end"
                aria-label="show-hide"
                onClick={() => handleOpen(5)}
              >
                <SaveIcon />
              </IconButton>
              <IconButton
                edge="end"
                aria-label="show-hide"
                onClick={() => exportRoute(3)}
              >
                <GetAppIcon />
              </IconButton>
              {/*<IconButton edge="end" aria-label="show-hide">
              <VisibilityIcon />
            </IconButton>*/}
              {/* </ListItem> */}
            </Grid>
          )}
        </List>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <div className={classes.modalPaper}>
              <h2 style={{ textAlign: "center" }} id="transition-modal-title">
                {routeName} Summary (Average)
              </h2>
              <List component="nav">
                <ListItem>
                  <ListItemText primary="Vulnerability Score:  " />
                  {vulnScore}
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText primary="Total Mileage:  " />
                  {mileage} miles
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText primary="Trip Duration:  " />
                  {formatTime(duration)}
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText primary={cellProvider} />
                  {cellCoverageScore}
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText primary="Incident Score:  " />
                  {incidentScore}
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText primary="Population Score:  " />
                  {popScore}
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText primary="Rail Crossings Score:  " />
                  {railScore}
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText primary="Out of Service Area Score:  " />
                  {outOfServiceAreaScore}
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText primary="Tunnels Score:  " />
                  {tunnelsScore}
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText primary="Weather Score:  " />
                  {weatherScore}
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText primary="Wildfire Score:  " />
                  {wildfiresScore}
                </ListItem>
              </List>
            </div>
          </Fade>
        </Modal>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open2}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open2}>
            <div className={classes.modalPaper}>
              <h2 style={{ textAlign: "center" }} id="transition-modal-title">
                {routeName2} Summary (Average)
              </h2>
              <List component="nav">
                <ListItem>
                  <ListItemText primary="Vulnerability Score:  " />
                  {vulnScore2}
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText primary="Total Mileage:  " />
                  {mileage2} miles
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText primary="Trip Duration:  " />
                  {formatTime(duration2)}
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText primary={cellProvider2} />
                  {cellCoverageScore2}
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText primary="Incident Score:  " />
                  {incidentScore2}
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText primary="Population Score:  " />
                  {popScore2}
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText primary="Rail Crossings Score:  " />
                  {railScore2}
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText primary="Out of Service Area Score:  " />
                  {outOfServiceAreaScore2}
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText primary="Tunnels Score:  " />
                  {tunnelsScore2}
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText primary="Weather Score:  " />
                  {weatherScore2}
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText primary="Wildfire Score:  " />
                  {wildfiresScore2}
                </ListItem>
              </List>
            </div>
          </Fade>
        </Modal>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open3}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open3}>
            <div className={classes.modalPaper}>
              <h2 style={{ textAlign: "center" }} id="transition-modal-title">
                {routeName3} Summary (Average)
              </h2>
              <List component="nav">
                <ListItem>
                  <ListItemText primary="Vulnerability Score:  " />
                  {vulnScore3}
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText primary="Total Mileage:  " />
                  {mileage3} miles
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText primary="Trip Duration:  " />
                  {formatTime(duration3)}
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText primary={cellProvider3} />
                  {cellCoverageScore3}
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText primary="Incident Score:  " />
                  {incidentScore3}
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText primary="Population Score:  " />
                  {popScore3}
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText primary="Rail Crossings Score:  " />
                  {railScore3}
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText primary="Out of Service Area Score:  " />
                  {outOfServiceAreaScore3}
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText primary="Tunnels Score:  " />
                  {tunnelsScore3}
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText primary="Weather Score:  " />
                  {weatherScore3}
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText primary="Wildfire Score:  " />
                  {wildfiresScore3}
                </ListItem>
              </List>
            </div>
          </Fade>
        </Modal>
        <Dialog
          open={openSave}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Save Route</DialogTitle>
          <DialogContent style={{ minWidth: 400 }}>
            <TextField
              autoFocus
              margin="dense"
              id="routename"
              label="Name Your Route"
              type="text"
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={saveRoute} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openSave2}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Save Route</DialogTitle>
          <DialogContent style={{ minWidth: 400 }}>
            <TextField
              autoFocus
              margin="dense"
              id="routename2"
              label="Name Your Route"
              type="text"
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={saveRoute2} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openSave3}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Save Route</DialogTitle>
          <DialogContent style={{ minWidth: 400 }}>
            <TextField
              autoFocus
              margin="dense"
              id="routename3"
              label="Name Your Route"
              type="text"
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={saveRoute3} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
        <Button
          style={{ visibility: "hidden" }}
          id="reset_route_checkbox"
          color="inherit"
          onClick={resetRouteCheckbox}
        ></Button>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <InputLabel>Base Layer</InputLabel>
        <ToggleButtonGroup
          value={baseLayer}
          exclusive
          onChange={handleBaseLayerChange}
        >
          <ToggleButton value="Streets">Streets</ToggleButton>
          <ToggleButton value="Satellite">Satellite</ToggleButton>
          <ToggleButton value="Hybrid">Hybrid</ToggleButton>
          <ToggleButton value="Navigation">Navigation</ToggleButton>
        </ToggleButtonGroup>
        <br></br>
        <br></br>
        <Divider />
        <List
          component="nav"
          className={classes.list}
          aria-label="mailbox folders"
        >
          <ListItem>
            <Popover
              className={classes.popover2}
              classes={{
                paper: classes.paper,
              }}
              open={popoverOpen}
              onClose={handlePopoverClose}
              disableRestoreFocus
              style={{
                position: "absolute",
                top: popoverPosition.top,
                left: popoverPosition.left,
              }}
            >
              <Typography id="popover-text" className={classes.popoverContent}>
                <p>
                  <b>Population data recommendations:</b>
                </p>
                <p>
                  • For <b>lower zoom</b> (big picture, entire route, or country
                  view), use <b>LandScan Global</b> (1km resolution)
                </p>
                <p>
                  • For <b>higher zoom</b> (city view or street view), use{" "}
                  <b>LandScan USA</b> (90m resolution)
                </p>
                <p>
                  More information on LandScan datasets is available at{" "}
                  <b>https://landscan.ornl.gov/about</b>
                </p>
              </Typography>
            </Popover>
            <Avatar
              alt="none"
              variant="rounded"
              className={classes.hospitals}
              src="/landscan_legend.png"
            />
            <ListItemText primary="Landscan USA Population (2021)" />
            {/* <InfoIcon
              id="ls-usa-info"
              aria-haspopup="true"
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
            ></InfoIcon> */}
            <Tooltip
              title="Improved visibility at higher zoom levels"
              placement="right"
              classes={{ tooltip: classes.zoomTooltip }}
            >
              <InfoIcon style={{ fontSize: "medium" }}></InfoIcon>
            </Tooltip>
            <Switch
              checked={LandscanUSA}
              onChange={handleCheckboxChange}
              color="primary"
              name="LandscanUSA"
            />
          </ListItem>
          <Divider />
          <ListItem>
            <Avatar
              alt="none"
              variant="rounded"
              className={classes.Mapbox_Traffic}
              src="/transparent.png"
            />
            <ListItemText primary="Mapbox Live Traffic" />
            <Tooltip
              title="Requires a zoom level of 7 or higher"
              placement="right"
              classes={{ tooltip: classes.zoomTooltip }}
            >
              <InfoIcon style={{ fontSize: "medium" }}></InfoIcon>
            </Tooltip>
            <Switch
              checked={Mapbox_Traffic}
              onChange={handleCheckboxChange}
              color="primary"
              name="Mapbox_Traffic"
            />
          </ListItem>
          <Divider />
          {/* <ListItem>
            <Avatar
              alt="none"
              variant="rounded"
              className={classes.hospitals}
              src="/landscan_legend.png"
            />
            <ListItemText primary="Landscan Global Population (2022)" />
            <InfoIcon
              aria-haspopup="true"
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
            ></InfoIcon>
            <Switch
              checked={LandscanGlobal}
              onChange={handleCheckboxChange}
              color="primary"
              name="LandscanGlobal"
            />
          </ListItem> */}
          <ListItem>
            <Avatar
              alt="none"
              variant="rounded"
              className={classes.noCellAny}
              src="/transparent.png"
            />
            <ListItemText primary="No Cell Coverage (Any Provider)" />
            <Tooltip
              title="Requires a zoom level of 7 or higher"
              placement="right"
              classes={{ tooltip: classes.zoomTooltip }}
            >
              <InfoIcon style={{ fontSize: "medium" }}></InfoIcon>
            </Tooltip>
            <Switch
              checked={Cell_Coverage}
              onChange={handleCheckboxChange}
              color="primary"
              name="Cell_Coverage"
            />
          </ListItem>
          <Divider />
          <ListItem>
            <Avatar
              alt="none"
              variant="rounded"
              className={classes.noCellAtt}
              src="/transparent.png"
            />
            <ListItemText primary="No Cell Coverage (AT&T)" />
            <Tooltip
              title="Requires a zoom level of 7 or higher"
              placement="right"
              classes={{ tooltip: classes.zoomTooltip }}
            >
              <InfoIcon style={{ fontSize: "medium" }}></InfoIcon>
            </Tooltip>
            <Switch
              checked={Cell_Coverage_ATT}
              onChange={handleCheckboxChange}
              color="primary"
              name="Cell_Coverage_ATT"
            />
          </ListItem>
          <Divider />
          <ListItem>
            <Avatar
              alt="none"
              variant="rounded"
              className={classes.noCellTmobile}
              src="/transparent.png"
            />
            <ListItemText primary="No Cell Coverage (T-Mobile)" />
            <Tooltip
              title="Requires a zoom level of 7 or higher"
              placement="right"
              classes={{ tooltip: classes.zoomTooltip }}
            >
              <InfoIcon style={{ fontSize: "medium" }}></InfoIcon>
            </Tooltip>
            <Switch
              checked={Cell_Coverage_T_Mobile}
              onChange={handleCheckboxChange}
              color="primary"
              name="Cell_Coverage_T_Mobile"
            />
          </ListItem>
          <Divider />
          <ListItem>
            <Avatar
              alt="none"
              variant="rounded"
              className={classes.noCellUsCellular}
              src="/transparent.png"
            />
            <ListItemText primary="No Cell Coverage (US Cellular)" />
            <Tooltip
              title="Requires a zoom level of 7 or higher"
              placement="right"
              classes={{ tooltip: classes.zoomTooltip }}
            >
              <InfoIcon style={{ fontSize: "medium" }}></InfoIcon>
            </Tooltip>
            <Switch
              checked={Cell_Coverage_US_Cellular}
              onChange={handleCheckboxChange}
              color="primary"
              name="Cell_Coverage_US_Cellular"
            />
          </ListItem>
          <Divider />
          <ListItem>
            <Avatar
              alt="none"
              variant="rounded"
              className={classes.noCellVerizon}
              src="/transparent.png"
            />
            <ListItemText primary="No Cell Coverage (Verizon)" />
            <Tooltip
              title="Requires a zoom level of 7 or higher"
              placement="right"
              classes={{ tooltip: classes.zoomTooltip }}
            >
              <InfoIcon style={{ fontSize: "medium" }}></InfoIcon>
            </Tooltip>
            <Switch
              checked={Cell_Coverage_Verizon}
              onChange={handleCheckboxChange}
              color="primary"
              name="Cell_Coverage_Verizon"
            />
          </ListItem>
          <Divider />
          <ListItem>
            <Avatar
              alt="none"
              variant="rounded"
              className={classes.NWS_Current_Warnings}
              src="/transparent.png"
            />
            <ListItemText primary="NWS Current Warnings" />
            <Switch
              checked={NWS_Current_Warnings}
              onChange={handleCheckboxChange}
              color="primary"
              name="NWS_Current_Warnings"
            />
          </ListItem>
          <Divider />
          <ListItem>
            <Avatar
              alt="none"
              variant="rounded"
              className={classes.NWS_Current_Watches}
              src="/transparent.png"
            />
            <ListItemText primary="NWS Current Watches" />
            <Switch
              checked={NWS_Current_Watches}
              onChange={handleCheckboxChange}
              color="primary"
              name="NWS_Current_Watches"
            />
          </ListItem>
          <Divider />
          <ListItem>
            <Avatar
              alt="none"
              variant="rounded"
              className={classes.NWS_Wildfires}
              src="/transparent.png"
            />
            <ListItemText primary="NWS Wildfires" />
            <Switch
              checked={NWS_Wildfires}
              onChange={handleCheckboxChange}
              color="primary"
              name="NWS_Wildfires"
            />
          </ListItem>
          <Divider />
          <ListItem>
            <Avatar
              alt="none"
              variant="rounded"
              className={classes.hospitals}
              src="/nws_weather_legend.png"
            />
            <ListItemText primary="NWS Weather Radar" />
            <Switch
              checked={NWS_Weather_Radar}
              onChange={handleCheckboxChange}
              color="primary"
              name="NWS_Weather_Radar"
            />
          </ListItem>
          <Divider />
          <ListItem>
            <Avatar
              alt="none"
              variant="rounded"
              className={classes.hospitals}
              src="https://cdn3.iconfinder.com/data/icons/medical-3-1/512/hospital_place-512.png"
            />
            <ListItemText primary="HIFLD Hospitals" />
            <Switch
              checked={HIFLD_Hospitals}
              onChange={handleCheckboxChange}
              color="primary"
              name="HIFLD_Hospitals"
            />
          </ListItem>
          <Divider />
          <ListItem>
            <Avatar
              alt="none"
              variant="rounded"
              className={classes.hospitals}
              src="https://static.thenounproject.com/png/1661277-200.png"
            />
            <ListItemText primary="HIFLD Police" />
            <Switch
              checked={HIFLD_Police}
              onChange={handleCheckboxChange}
              color="primary"
              name="HIFLD_Police"
            />
          </ListItem>
          <Divider />
          <ListItem>
            <Avatar
              alt="none"
              variant="rounded"
              className={classes.hospitals}
              src="https://cdn4.iconfinder.com/data/icons/health-medicine-volume-i/128/ems-symbol-1024.png"
            />
            <ListItemText primary="HIFLD EMS" />
            <Switch
              checked={HIFLD_EMS}
              onChange={handleCheckboxChange}
              color="primary"
              name="HIFLD_EMS"
            />
          </ListItem>
          <Divider />
          <ListItem>
            <Avatar
              alt="none"
              className={classes.hifldWeightStations}
              src="/transparent.png"
            />
            <ListItemText primary="HIFLD Weigh Stations" />
            <Switch
              checked={HIFLD_Weigh_Stations}
              onChange={handleCheckboxChange}
              color="primary"
              name="HIFLD_Weigh_Stations"
            />
          </ListItem>
          <Divider />
          <ListItem>
            <Avatar
              alt="none"
              className={classes.hifldTunnels}
              src="/transparent.png"
            />
            <ListItemText primary="HIFLD Tunnels" />
            <Switch
              checked={HIFLD_Tunnels}
              onChange={handleCheckboxChange}
              color="primary"
              name="HIFLD_Tunnels"
            />
          </ListItem>
          <Divider />
          <ListItem>
            <Avatar
              alt="none"
              className={classes.hifldRailJunctions}
              src="/transparent.png"
            />
            <ListItemText primary="HIFLD Rail Crossings" />
            <Tooltip
              title="Requires a zoom level of 7 or higher"
              placement="right"
              classes={{ tooltip: classes.zoomTooltip }}
            >
              <InfoIcon style={{ fontSize: "medium" }}></InfoIcon>
            </Tooltip>
            <Switch
              checked={HIFLD_Rail_Juntions}
              onChange={handleCheckboxChange}
              color="primary"
              name="HIFLD_Rail_Juntions"
            />
          </ListItem>
          <Divider />
          <ListItem>
            <Avatar
              alt="none"
              className={classes.hifldBridges}
              src="/transparent.png"
            />
            <ListItemText primary="HIFLD Bridges" />
            <Tooltip
              title="Requires a zoom level of 7 or higher"
              placement="right"
              classes={{ tooltip: classes.zoomTooltip }}
            >
              <InfoIcon style={{ fontSize: "medium" }}></InfoIcon>
            </Tooltip>
            <Switch
              checked={HIFLD_Bridges}
              onChange={handleCheckboxChange}
              color="primary"
              name="HIFLD_Bridges"
            />
          </ListItem>
          <Divider />
        </List>
        {/*<FormControl component="fieldset" className={classes.formControl}>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox checked={NWS_Current_Warnings} onChange={handleCheckboxChange} color="primary" name="NWS_Current_Warnings" />}
              label="NWS Current Warnings"
            />
            <FormControlLabel
              control={<Checkbox checked={NWS_Current_Watches} onChange={handleCheckboxChange} color="primary" name="NWS_Current_Watches" />}
              label="NWS Current Watches"
            />
            <FormControlLabel
              control={<Checkbox checked={NWS_Wildfires} onChange={handleCheckboxChange} color="primary" name="NWS_Wildfires" />}
              label="NWS Wildfires"
            />
            <FormControlLabel
              control={<Checkbox checked={NWS_Weather_Radar} onChange={handleCheckboxChange} color="primary" name="NWS_Weather_Radar" />}
              label="NWS Weather Radar"
            />
            <FormControlLabel
              control={<Checkbox checked={HIFLD_Hospitals} onChange={handleCheckboxChange} color="primary" name="HIFLD_Hospitals" />}
              label="HIFLD Hospitals"
            />
            <FormControlLabel
              control={<Checkbox checked={HIFLD_Police} onChange={handleCheckboxChange} color="primary" name="HIFLD_Police" />}
              label="HIFLD Police"
            />
            <FormControlLabel
              control={<Checkbox checked={HIFLD_Weigh_Stations} onChange={handleCheckboxChange} color="primary" name="HIFLD_Weigh_Stations" />}
              label="HIFLD Weigh Stations"
            />
            <FormControlLabel
              control={<Checkbox checked={HIFLD_EMS} onChange={handleCheckboxChange} color="primary" name="HIFLD_EMS" />}
              label="HIFLD EMS"
            />
            <FormControlLabel
              control={<Checkbox checked={HIFLD_Tunnels} onChange={handleCheckboxChange} color="primary" name="HIFLD_Tunnels" />}
              label="HIFLD Tunnels"
            />
            <FormControlLabel
              control={<Checkbox checked={HIFLD_Rail_Juntions} onChange={handleCheckboxChange} color="primary" name="HIFLD_Rail_Juntions" />}
              label="HIFLD Rail Junctions"
            />
            <FormControlLabel
              control={<Checkbox checked={HIFLD_Bridges} onChange={handleCheckboxChange} color="primary" name="HIFLD_Bridges" />}
              label="HIFLD Bridges"
            />
          </FormGroup>
        </FormControl>*/}
      </TabPanel>
      <TabPanel value={value} index={2}>
        {/* <Popover
          className={classes.popover}
          classes={{
            paper: classes.paper,
          }}
          open={popoverOpen}
          onClose={handlePopoverClose}
          disableRestoreFocus
          style={{
            position: "absolute",
            top: popoverPosition.top,
            left: popoverPosition.left,
          }}
        >
          <img src="/likert.png" className={classes.likert_image} />
        </Popover> */}
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          style={{ width: "100%" }}
        >
          <InputLabel>Risk Avoidance Factor (Likert Scale)</InputLabel>
          <Tooltip
            title={
              <>
                1 - 3 <b>Acceptable</b>
                <br />4 - 6 <b>Undesirable</b>
                <br />7 - 9 <b>Highly Undesirable</b>
                <br />
                10 <b>Absolutely No-Go</b>
              </>
            }
            placement="right"
            classes={{ tooltip: classes.zoomTooltip }}
          >
            <InfoIcon
              aria-haspopup="true"
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
              style={{ marginLeft: 8, fontSize: "1rem" }}
            />
          </Tooltip>
        </Box>
        <br></br>
        {/*<Box pr={15} pl={15} pt={6}>
            <Typography gutterBottom>
              Delays
            </Typography>
            <Slider id="delays-slider"
              onChange={handleSliderChange}
              defaultValue={50}
              getAriaValueText={valuetext}
              aria-labelledby="discrete-slider"
              valueLabelDisplay="auto"
              step={1}
              marks
              min={1}
              max={10}
            />
          </Box>*/}
        <Box
          pr={3}
          pl={3}
          pt={1}
          mb={1}
          border="1px solid gray"
          style={{
            opacity: bridgeOverLandVisibility ? 1 : 0.5,
            pointerEvents: bridgeOverLandVisibility ? "auto" : "none",
          }}
        >
          <Typography gutterBottom style={{ display: "inline-block" }}>
            Bridge Over Land
          </Typography>
          <BridgeOverLandSlider
            track={false}
            onChangeCommitted={changeBridgeOverLandSlider}
            defaultValue={bridgeOverLandSliderValue}
            aria-labelledby="discrete-slider"
            valueLabelDisplay="auto"
            step={0.1}
            marks={[
              { value: 1, label: "1" },
              { value: 2, label: "2" },
              { value: 2.3, label: "" },
              { value: 3, label: "3" },
              { value: 4, label: "4" },
              { value: 5, label: "5" },
              { value: 6, label: "6" },
              { value: 7, label: "7" },
              { value: 8, label: "8" },
              { value: 9, label: "9" },
              { value: 10, label: "10" },
            ]}
            min={1.0}
            max={10.0}
            disabled={!bridgeOverLandVisibility}
          />
          {infoStates.BridgeOverLandInfo && (
            <div>
              <Grid
                container
                spacing={1}
                direction="row"
                justifyContent="space-evenly"
              >
                <Grid item xs={3} style={{ marginTop: 0 }}>
                  <p style={{ fontSize: "0.75rem", cursor: "default" }}>
                    Acceptable <br /> (Route Change Optional)
                  </p>
                </Grid>
                <Grid item xs={4} style={{ marginTop: 0 }}>
                  <p style={{ fontSize: "0.75rem", cursor: "default" }}>
                    Undesirable <br /> (Route Change Preferred)
                  </p>
                </Grid>
                <Grid item xs={3} style={{ marginTop: 0 }}>
                  <p style={{ fontSize: "0.75rem", cursor: "default" }}>
                    Highly Undesirable (Route Change Highly Preferred)
                  </p>
                </Grid>
                <Grid item xs={2} style={{ marginTop: 0 }}>
                  <p style={{ fontSize: "0.75rem", cursor: "default" }}>
                    No Go (Required)
                  </p>
                </Grid>
              </Grid>
              <p style={{ fontSize: "0.75rem", cursor: "default" }}>
                {bridgeOverLand?.["sentence_1"]}{" "}
                {bridgeOverLand?.["sentence_2"]} {bridgeOverLand?.["sentence3"]}
              </p>
            </div>
          )}
          <div
            style={{
              marginTop: "10px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Tooltip
              placement="right"
              classes={{ tooltip: classes.zoomTooltip }}
              title={
                bridgeOverLandVisibility
                  ? "Including this risk factor in calculations"
                  : "Excluding this risk factor from calculations"
              }
              arrow
            >
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch(toggleBridgeOverLandVisibility());
                }}
                style={{
                  pointerEvents: "auto", // Always allow interaction
                  opacity: 1, // Keep button fully visible
                }}
              >
                {bridgeOverLandVisibility ? (
                  <VisibilityIcon fontSize="small" />
                ) : (
                  <VisibilityOffIcon fontSize="small" />
                )}
              </IconButton>
            </Tooltip>

            <Button
              color="primary"
              size="small"
              onClick={() => toggleInfo("BridgeOverLandInfo")}
              disabled={!bridgeOverLandVisibility}
            >
              {infoStates.BridgeOverLandInfo ? "Hide Info" : "More Info"}
            </Button>
          </div>
        </Box>

        <Box
          pr={3}
          pl={3}
          pt={1}
          mb={1}
          border="1px solid gray"
          style={{
            opacity: bridgeOverWaterVisibility ? 1 : 0.5,
            pointerEvents: bridgeOverWaterVisibility ? "auto" : "none",
          }}
        >
          <Typography gutterBottom style={{ display: "inline-block" }}>
            Bridge Over Water
          </Typography>
          <BridgeOverWaterSlider
            track={false}
            onChangeCommitted={changeBridgeOverWaterSlider}
            defaultValue={bridgeOverWaterSliderValue}
            aria-labelledby="discrete-slider"
            valueLabelDisplay="auto"
            step={0.1}
            marks={[
              { value: 1, label: "1" },
              { value: 2, label: "2" },
              { value: 3, label: "3" },
              { value: 4, label: "4" },
              { value: 5, label: "5" },
              { value: 6, label: "6" },
              { value: 7, label: "7" },
              { value: 8, label: "8" },
              { value: 9, label: "9" },
              { value: 10, label: "10" },
            ]}
            min={1.0}
            max={10.0}
            disabled={!bridgeOverWaterVisibility}
          />
          {infoStates.BridgeOverWaterInfo && (
            <div>
              <Grid
                container
                spacing={1}
                direction="row"
                justifyContent="space-evenly"
              >
                <Grid item xs={3} style={{ marginTop: 0 }}>
                  <p style={{ fontSize: "0.75rem", cursor: "default" }}>
                    Acceptable <br /> (Route Change Optional)
                  </p>
                </Grid>
                <Grid item xs={4} style={{ marginTop: 0 }}>
                  <p style={{ fontSize: "0.75rem", cursor: "default" }}>
                    Undesirable <br /> (Route Change Preferred)
                  </p>
                </Grid>
                <Grid item xs={3} style={{ marginTop: 0 }}>
                  <p style={{ fontSize: "0.75rem", cursor: "default" }}>
                    Highly Undesirable (Route Change Highly Preferred)
                  </p>
                </Grid>
                <Grid item xs={2} style={{ marginTop: 0 }}>
                  <p style={{ fontSize: "0.75rem", cursor: "default" }}>
                    No Go (Required)
                  </p>
                </Grid>
              </Grid>
              <p style={{ fontSize: "0.75rem", cursor: "default" }}>
                {bridgeOverWater?.["sentence_1"]}{" "}
                {bridgeOverWater?.["sentence_2"]}{" "}
                {bridgeOverWater?.["sentence3"]}
              </p>
            </div>
          )}
          <div
            style={{
              marginTop: "10px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Tooltip
              placement="right"
              classes={{ tooltip: classes.zoomTooltip }}
              title={
                bridgeOverWaterVisibility
                  ? "Including this risk factor in calculations"
                  : "Excluding this risk factor from calculations"
              }
              arrow
            >
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch(toggleBridgeOverWaterVisibility());
                }}
                style={{
                  pointerEvents: "auto", // Always allow interaction
                  opacity: 1, // Keep button fully visible
                }}
              >
                {bridgeOverWaterVisibility ? (
                  <VisibilityIcon fontSize="small" />
                ) : (
                  <VisibilityOffIcon fontSize="small" />
                )}
              </IconButton>
            </Tooltip>

            <Button
              color="primary"
              size="small"
              onClick={() => toggleInfo("BridgeOverWaterInfo")}
              disabled={!bridgeOverWaterVisibility}
            >
              {infoStates.BridgeOverWaterInfo ? "Hide Info" : "More Info"}
            </Button>
          </div>
        </Box>

        <Box
          pr={3}
          pl={3}
          pt={1}
          mb={1}
          border="1px solid gray"
          style={{
            opacity: bridgeOverpassVisibility ? 1 : 0.5,
            pointerEvents: bridgeOverpassVisibility ? "auto" : "none",
          }}
        >
          <Typography gutterBottom style={{ display: "inline-block" }}>
            Bridge Overpass/Underpass
          </Typography>
          <BridgeOverpassesSlider
            track={false}
            onChangeCommitted={changeBridgeOverpassSlider}
            defaultValue={bridgeOverpassSliderValue}
            aria-labelledby="discrete-slider"
            valueLabelDisplay="auto"
            step={0.1}
            marks={[
              { value: 1, label: "1" },
              { value: 2, label: "2" },
              { value: 2.2 },
              { value: 3, label: "3" },
              { value: 4, label: "4" },
              { value: 5, label: "5" },
              { value: 6, label: "6" },
              { value: 7, label: "7" },
              { value: 8, label: "8" },
              { value: 9, label: "9" },
              { value: 10, label: "10" },
            ]}
            min={1.0}
            max={10.0}
            disabled={!bridgeOverpassVisibility}
          />
          {infoStates.BridgeOverpassesInfo && (
            <div>
              <Grid
                container
                spacing={1}
                direction="row"
                justifyContent="space-evenly"
              >
                <Grid item xs={3} style={{ marginTop: 0 }}>
                  <p style={{ fontSize: "0.75rem", cursor: "default" }}>
                    Acceptable <br /> (Route Change Optional)
                  </p>
                </Grid>
                <Grid item xs={4} style={{ marginTop: 0 }}>
                  <p style={{ fontSize: "0.75rem", cursor: "default" }}>
                    Undesirable <br /> (Route Change Preferred)
                  </p>
                </Grid>
                <Grid item xs={3} style={{ marginTop: 0 }}>
                  <p style={{ fontSize: "0.75rem", cursor: "default" }}>
                    Highly Undesirable (Route Change Highly Preferred)
                  </p>
                </Grid>
                <Grid item xs={2} style={{ marginTop: 0 }}>
                  <p style={{ fontSize: "0.75rem", cursor: "default" }}>
                    No Go (Required)
                  </p>
                </Grid>
              </Grid>
              <p style={{ fontSize: "0.75rem", cursor: "default" }}>
                {bridgeOverpasses?.["sentence_1"]}{" "}
                {bridgeOverpasses?.["sentence_2"]}{" "}
                {bridgeOverpasses?.["sentence3"]}
              </p>
            </div>
          )}
          <div
            style={{
              marginTop: "10px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Tooltip
              placement="right"
              classes={{ tooltip: classes.zoomTooltip }}
              title={
                bridgeOverpassVisibility
                  ? "Including this risk factor in calculations"
                  : "Excluding this risk factor from calculations"
              }
              arrow
            >
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch(toggleBridgeOverpassVisibility());
                }}
                style={{
                  pointerEvents: "auto", // Always allow interaction
                  opacity: 1, // Keep button fully visible
                }}
              >
                {bridgeOverpassVisibility ? (
                  <VisibilityIcon fontSize="small" />
                ) : (
                  <VisibilityOffIcon fontSize="small" />
                )}
              </IconButton>
            </Tooltip>

            <Button
              color="primary"
              size="small"
              onClick={() => toggleInfo("BridgeOverpassesInfo")}
              disabled={!bridgeOverpassVisibility}
            >
              {infoStates.BridgeOverpassesInfo ? "Hide Info" : "More Info"}
            </Button>
          </div>
        </Box>

        {/* <Box pr={3} pl={3} pt={1} mb={1} border="1px solid gray">
          <Typography gutterBottom style={{ display: "inline-block" }}>
            Bridge Type Unknown
          </Typography>
          <BridgeTypeUnknownSlider
            track={false}
            defaultValue={bridgeTypeUknown?.["mid_median"] || 0}
            aria-labelledby="discrete-slider"
            valueLabelDisplay="auto"
            step={0.1}
            marks={[
              { value: 1, label: "1" },
              { value: 2, label: "2" },
              {
                value: bridgeTypeUknown?.["mid_median"] || 2.75,
              },
              { value: 3, label: "3" },
              { value: 4, label: "4" },
              { value: 5, label: "5" },
              { value: 6, label: "6" },
              { value: 7, label: "7" },
              { value: 8, label: "8" },
              { value: 9, label: "9" },
              { value: 10, label: "10" },
            ]}
            min={1.0}
            max={10.0}
          />
          {infoStates.BridgeTypeUnknownInfo && (
            <div>
              <Grid
                container
                spacing={1}
                direction="row"
                justifyContent="space-evenly"
              >
                <Grid item xs={3} style={{ marginTop: 0 }}>
                  <p style={{ fontSize: "0.75rem", cursor: "default" }}>
                    Acceptable <br></br>(Route Change Optional)
                  </p>
                </Grid>
                <Grid item xs={4} style={{ marginTop: 0 }}>
                  <p style={{ fontSize: "0.75rem", cursor: "default" }}>
                    Undesirable<br></br>(Route Change Preferred)
                  </p>
                </Grid>
                <Grid item xs={3} style={{ marginTop: 0 }}>
                  <p style={{ fontSize: "0.75rem", cursor: "default" }}>
                    Highly Undesirable (Route Change Highly Preferred)
                  </p>
                </Grid>
                <Grid item xs={2} style={{ marginTop: 0 }}>
                  <p style={{ fontSize: "0.75rem", cursor: "default" }}>
                    No Go (Required)
                  </p>
                </Grid>
              </Grid>
              <p style={{ fontSize: "0.75rem", cursor: "default" }}>
                {bridgeTypeUknown?.["sentence_1"]}{" "}
                {bridgeTypeUknown?.["sentence_2"]}{" "}
                {bridgeTypeUknown?.["sentence3"]}
              </p>
            </div>
          )}
          <div style={{ textAlign: "right", marginTop: "10px" }}>
            <Button
              color="primary"
              size="small"
              onClick={() => toggleInfo("BridgeTypeUnknownInfo")}
            >
              {infoStates.BridgeTypeUnknownInfo ? "Hide Info" : "More Info"}
            </Button>
          </div>
        </Box> */}
        <Box
          pr={3}
          pl={3}
          pt={1}
          mb={1}
          border="1px solid gray"
          style={{
            opacity: populationRuralVisibility ? 1 : 0.5,
            pointerEvents: populationRuralVisibility ? "auto" : "none",
          }}
        >
          <Typography gutterBottom style={{ display: "inline-block" }}>
            Population: Rural
          </Typography>
          <PopulationRuralSlider
            track={false}
            onChangeCommitted={changePopulationRuralSlider}
            defaultValue={populationRuralSliderValue}
            aria-labelledby="discrete-slider"
            valueLabelDisplay="auto"
            step={0.1}
            marks={[
              { value: 1, label: "1" },
              { value: 2, label: "2" },
              {
                value: populationRural?.["mid_median"] || 2,
              },
              { value: 3, label: "3" },
              { value: 4, label: "4" },
              { value: 5, label: "5" },
              { value: 6, label: "6" },
              { value: 7, label: "7" },
              { value: 8, label: "8" },
              { value: 9, label: "9" },
              { value: 10, label: "10" },
            ]}
            min={1.0}
            max={10.0}
            disabled={!populationRuralVisibility}
          />
          {infoStates.PopulationRuralInfo && (
            <div>
              <Grid
                container
                spacing={1}
                direction="row"
                justifyContent="space-evenly"
              >
                <Grid item xs={3} style={{ marginTop: 0 }}>
                  <p style={{ fontSize: "0.75rem", cursor: "default" }}>
                    Acceptable <br /> (Route Change Optional)
                  </p>
                </Grid>
                <Grid item xs={4} style={{ marginTop: 0 }}>
                  <p style={{ fontSize: "0.75rem", cursor: "default" }}>
                    Undesirable <br /> (Route Change Preferred)
                  </p>
                </Grid>
                <Grid item xs={3} style={{ marginTop: 0 }}>
                  <p style={{ fontSize: "0.75rem", cursor: "default" }}>
                    Highly Undesirable (Route Change Highly Preferred)
                  </p>
                </Grid>
                <Grid item xs={2} style={{ marginTop: 0 }}>
                  <p style={{ fontSize: "0.75rem", cursor: "default" }}>
                    No Go (Required)
                  </p>
                </Grid>
              </Grid>
              <p style={{ fontSize: "0.75rem", cursor: "default" }}>
                {populationRural?.["sentence_1"]}{" "}
                {populationRural?.["sentence_2"]}{" "}
                {populationRural?.["sentence3"]}
              </p>
            </div>
          )}
          <div
            style={{
              marginTop: "10px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Tooltip
              placement="right"
              classes={{ tooltip: classes.zoomTooltip }}
              title={
                populationRuralVisibility
                  ? "Including this risk factor in calculations"
                  : "Excluding this risk factor from calculations"
              }
              arrow
            >
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch(togglePopulationRuralVisibility());
                }}
                style={{
                  pointerEvents: "auto",
                  opacity: 1,
                }}
              >
                {populationRuralVisibility ? (
                  <VisibilityIcon fontSize="small" />
                ) : (
                  <VisibilityOffIcon fontSize="small" />
                )}
              </IconButton>
            </Tooltip>

            <Button
              color="primary"
              size="small"
              onClick={() => toggleInfo("PopulationRuralInfo")}
              disabled={!populationRuralVisibility}
            >
              {infoStates.PopulationRuralInfo ? "Hide Info" : "More Info"}
            </Button>
          </div>
        </Box>

        <Box
          pr={3}
          pl={3}
          pt={1}
          mb={1}
          border="1px solid gray"
          style={{
            opacity: populationSuburbanVisibility ? 1 : 0.5,
            pointerEvents: populationSuburbanVisibility ? "auto" : "none",
          }}
        >
          <Typography gutterBottom style={{ display: "inline-block" }}>
            Population: Suburban
          </Typography>
          <PopulationSuburbanSlider
            track={false}
            onChangeCommitted={changePopulationSuburbanSlider}
            defaultValue={populationSuburbanSliderValue}
            aria-labelledby="discrete-slider"
            valueLabelDisplay="auto"
            step={0.1}
            marks={[
              { value: 1, label: "1" },
              { value: 2, label: "2" },
              { value: 3, label: "3" },
              {
                value: populationSuburban?.["mid_median"] || 3.5,
              },
              { value: 4, label: "4" },
              { value: 5, label: "5" },
              { value: 6, label: "6" },
              { value: 7, label: "7" },
              { value: 8, label: "8" },
              { value: 9, label: "9" },
              { value: 10, label: "10" },
            ]}
            min={1.0}
            max={10.0}
            disabled={!populationSuburbanVisibility}
          />
          {infoStates.PopulationSuburbanInfo && (
            <div>
              <Grid
                container
                spacing={1}
                direction="row"
                justifyContent="space-evenly"
              >
                <Grid item xs={3} style={{ marginTop: 0 }}>
                  <p style={{ fontSize: "0.75rem", cursor: "default" }}>
                    Acceptable <br /> (Route Change Optional)
                  </p>
                </Grid>
                <Grid item xs={4} style={{ marginTop: 0 }}>
                  <p style={{ fontSize: "0.75rem", cursor: "default" }}>
                    Undesirable <br /> (Route Change Preferred)
                  </p>
                </Grid>
                <Grid item xs={3} style={{ marginTop: 0 }}>
                  <p style={{ fontSize: "0.75rem", cursor: "default" }}>
                    Highly Undesirable (Route Change Highly Preferred)
                  </p>
                </Grid>
                <Grid item xs={2} style={{ marginTop: 0 }}>
                  <p style={{ fontSize: "0.75rem", cursor: "default" }}>
                    No Go (Required)
                  </p>
                </Grid>
              </Grid>
              <p style={{ fontSize: "0.75rem", cursor: "default" }}>
                {populationSuburban?.["sentence_1"]}{" "}
                {populationSuburban?.["sentence_2"]}{" "}
                {populationSuburban?.["sentence3"]}
              </p>
            </div>
          )}
          <div
            style={{
              marginTop: "10px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Tooltip
              placement="right"
              classes={{ tooltip: classes.zoomTooltip }}
              title={
                populationSuburbanVisibility
                  ? "Including this risk factor in calculations"
                  : "Excluding this risk factor from calculations"
              }
              arrow
            >
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch(togglePopulationSuburbanVisibility());
                }}
                style={{
                  pointerEvents: "auto",
                  opacity: 1,
                }}
              >
                {populationSuburbanVisibility ? (
                  <VisibilityIcon fontSize="small" />
                ) : (
                  <VisibilityOffIcon fontSize="small" />
                )}
              </IconButton>
            </Tooltip>

            <Button
              color="primary"
              size="small"
              onClick={() => toggleInfo("PopulationSuburbanInfo")}
              disabled={!populationSuburbanVisibility}
            >
              {infoStates.PopulationSuburbanInfo ? "Hide Info" : "More Info"}
            </Button>
          </div>
        </Box>

        <Box
          pr={3}
          pl={3}
          pt={1}
          mb={1}
          border="1px solid gray"
          style={{
            opacity: populationUrbanVisibility ? 1 : 0.5,
            pointerEvents: populationUrbanVisibility ? "auto" : "none",
          }}
        >
          <Typography gutterBottom style={{ display: "inline-block" }}>
            Population: Urban
          </Typography>
          <PopulationUrbanSlider
            track={false}
            onChangeCommitted={changePopulationUrbanSlider}
            defaultValue={populationUrbanSliderValue}
            aria-labelledby="discrete-slider"
            valueLabelDisplay="auto"
            step={0.1}
            marks={[
              { value: 1, label: "1" },
              { value: 2, label: "2" },
              { value: 3, label: "3" },
              {
                value: populationUrban?.["mid_median"] || 3.5,
              },
              { value: 4, label: "4" },
              { value: 5, label: "5" },
              { value: 6, label: "6" },
              { value: 7, label: "7" },
              { value: 8, label: "8" },
              { value: 9, label: "9" },
              { value: 10, label: "10" },
            ]}
            min={1.0}
            max={10.0}
            disabled={!populationUrbanVisibility}
          />
          {infoStates.PopulationUrbanInfo && (
            <div>
              <Grid
                container
                spacing={1}
                direction="row"
                justifyContent="space-evenly"
              >
                <Grid item xs={3} style={{ marginTop: 0 }}>
                  <p style={{ fontSize: "0.75rem", cursor: "default" }}>
                    Acceptable <br /> (Route Change Optional)
                  </p>
                </Grid>
                <Grid item xs={4} style={{ marginTop: 0 }}>
                  <p style={{ fontSize: "0.75rem", cursor: "default" }}>
                    Undesirable <br /> (Route Change Preferred)
                  </p>
                </Grid>
                <Grid item xs={3} style={{ marginTop: 0 }}>
                  <p style={{ fontSize: "0.75rem", cursor: "default" }}>
                    Highly Undesirable (Route Change Highly Preferred)
                  </p>
                </Grid>
                <Grid item xs={2} style={{ marginTop: 0 }}>
                  <p style={{ fontSize: "0.75rem", cursor: "default" }}>
                    No Go (Required)
                  </p>
                </Grid>
              </Grid>
              <p style={{ fontSize: "0.75rem", cursor: "default" }}>
                {populationUrban?.["sentence_1"]}{" "}
                {populationUrban?.["sentence_2"]}{" "}
                {populationUrban?.["sentence3"]}
              </p>
            </div>
          )}
          <div
            style={{
              marginTop: "10px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Tooltip
              placement="right"
              classes={{ tooltip: classes.zoomTooltip }}
              title={
                populationUrbanVisibility
                  ? "Including this risk factor in calculations"
                  : "Excluding this risk factor from calculations"
              }
              arrow
            >
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch(togglePopulationUrbanVisibility());
                }}
                style={{
                  pointerEvents: "auto",
                  opacity: 1,
                }}
              >
                {populationUrbanVisibility ? (
                  <VisibilityIcon fontSize="small" />
                ) : (
                  <VisibilityOffIcon fontSize="small" />
                )}
              </IconButton>
            </Tooltip>

            <Button
              color="primary"
              size="small"
              onClick={() => toggleInfo("PopulationUrbanInfo")}
              disabled={!populationUrbanVisibility}
            >
              {infoStates.PopulationUrbanInfo ? "Hide Info" : "More Info"}
            </Button>
          </div>
        </Box>

        {/* <Box pr={3} pl={3} pt={1} mb={1} border="1px solid gray">
          <Typography gutterBottom style={{ display: "inline-block" }}>
            Railroad Clearance Unknown
          </Typography>
          <RailroadClearanceUnknownSlider
            track={false}
            defaultValue={railroadClearanceUnknown?.["mid_median"] || 0}
            aria-labelledby="discrete-slider"
            valueLabelDisplay="auto"
            step={0.1}
            marks={[
              { value: 1, label: "1" },
              { value: 2, label: "2" },
              { value: 3, label: "3" },
              { value: 4, label: "4" },
              { value: 5, label: "5" },
              {
                value: railroadClearanceUnknown?.["mid_median"] || 5.9,
              },
              { value: 6, label: "6" },
              { value: 7, label: "7" },
              { value: 8, label: "8" },
              { value: 9, label: "9" },
              { value: 10, label: "10" },
            ]}
            min={1.0}
            max={10.0}
          />
          {infoStates.RailroadClearanceUnknownInfo && (
            <div>
              <Grid
                container
                spacing={1}
                direction="row"
                justifyContent="space-evenly"
              >
                <Grid item xs={3} style={{ marginTop: 0 }}>
                  <p style={{ fontSize: "0.75rem", cursor: "default" }}>
                    Acceptable <br></br>(Route Change Optional)
                  </p>
                </Grid>
                <Grid item xs={4} style={{ marginTop: 0 }}>
                  <p style={{ fontSize: "0.75rem", cursor: "default" }}>
                    Undesirable<br></br>(Route Change Preferred)
                  </p>
                </Grid>
                <Grid item xs={3} style={{ marginTop: 0 }}>
                  <p style={{ fontSize: "0.75rem", cursor: "default" }}>
                    Highly Undesirable (Route Change Highly Preferred)
                  </p>
                </Grid>
                <Grid item xs={2} style={{ marginTop: 0 }}>
                  <p style={{ fontSize: "0.75rem", cursor: "default" }}>
                    No Go (Required)
                  </p>
                </Grid>
              </Grid>
              <p style={{ fontSize: "0.75rem", cursor: "default" }}>
                {railroadClearanceUnknown?.["sentence_1"]}{" "}
                {railroadClearanceUnknown?.["sentence_2"]}{" "}
                {railroadClearanceUnknown?.["sentence3"]}
              </p>
            </div>
          )}
          <div style={{ textAlign: "right", marginTop: "10px" }}>
            <Button
              color="primary"
              size="small"
              onClick={() => toggleInfo("RailroadClearanceUnknownInfo")}
            >
              {infoStates.RailroadClearanceUnknownInfo
                ? "Hide Info"
                : "More Info"}
            </Button>
          </div>
        </Box>
        <Box pr={3} pl={3} pt={1} mb={1} border="1px solid gray">
          <Typography gutterBottom style={{ display: "inline-block" }}>
            Railroad Low Clearance
          </Typography>
          <RailroadLowClearanceSlider
            track={false}
            defaultValue={railroadLowClearance?.["mid_median"] || 0}
            aria-labelledby="discrete-slider"
            valueLabelDisplay="auto"
            step={0.1}
            marks={[
              { value: 1, label: "1" },
              { value: 2, label: "2" },
              { value: 3, label: "3" },
              { value: 4, label: "4" },
              { value: 5, label: "5" },
              { value: 6, label: "6" },
              { value: railroadLowClearance?.["mid_median"] || 6.8 },
              { value: 7, label: "7" },
              { value: 8, label: "8" },
              { value: 9, label: "9" },
              { value: 10, label: "10" },
            ]}
            min={1.0}
            max={10.0}
          />
          {infoStates.RailroadLowClearanceInfo && (
            <div>
              <Grid
                container
                spacing={1}
                direction="row"
                justifyContent="space-evenly"
              >
                <Grid item xs={3} style={{ marginTop: 0 }}>
                  <p style={{ fontSize: "0.75rem", cursor: "default" }}>
                    Acceptable <br></br>(Route Change Optional)
                  </p>
                </Grid>
                <Grid item xs={4} style={{ marginTop: 0 }}>
                  <p style={{ fontSize: "0.75rem", cursor: "default" }}>
                    Undesirable<br></br>(Route Change Preferred)
                  </p>
                </Grid>
                <Grid item xs={3} style={{ marginTop: 0 }}>
                  <p style={{ fontSize: "0.75rem", cursor: "default" }}>
                    Highly Undesirable (Route Change Highly Preferred)
                  </p>
                </Grid>
                <Grid item xs={2} style={{ marginTop: 0 }}>
                  <p style={{ fontSize: "0.75rem", cursor: "default" }}>
                    No Go (Required)
                  </p>
                </Grid>
              </Grid>
              <p style={{ fontSize: "0.75rem", cursor: "default" }}>
                {railroadLowClearance?.["sentence_1"]}{" "}
                {railroadLowClearance?.["sentence_2"]}{" "}
                {railroadLowClearance?.["sentence3"]}
              </p>
            </div>
          )}
          <div style={{ textAlign: "right", marginTop: "10px" }}>
            <Button
              color="primary"
              size="small"
              onClick={() => toggleInfo("RailroadLowClearanceInfo")}
            >
              {infoStates.RailroadLowClearanceInfo ? "Hide Info" : "More Info"}
            </Button>
          </div>
        </Box> */}
        <Box
          pr={3}
          pl={3}
          pt={1}
          mb={1}
          border="1px solid gray"
          style={{
            opacity: railVisibility ? 1 : 0.5,
            pointerEvents: railVisibility ? "auto" : "none",
          }}
        >
          <Typography gutterBottom style={{ display: "inline-block" }}>
            Railroad Typical Clearance
          </Typography>
          <RailroadTypicalClearanceSlider
            track={false}
            onChangeCommitted={changeRailSlider}
            defaultValue={railSliderValue}
            aria-labelledby="discrete-slider"
            valueLabelDisplay="auto"
            step={0.1}
            marks={[
              { value: 1, label: "1" },
              { value: 2, label: "2" },
              { value: 3, label: "3" },
              { value: 4, label: "4" },
              { value: 5, label: "5" },
              { value: 6, label: "6" },
              { value: 7, label: "7" },
              { value: 8, label: "8" },
              { value: 9, label: "9" },
              { value: 10, label: "10" },
            ]}
            min={1.0}
            max={10.0}
            disabled={!railVisibility}
          />
          {infoStates.RailroadTypicalClearanceInfo && (
            <div>
              <Grid
                container
                spacing={1}
                direction="row"
                justifyContent="space-evenly"
              >
                <Grid item xs={3} style={{ marginTop: 0 }}>
                  <p style={{ fontSize: "0.75rem", cursor: "default" }}>
                    Acceptable <br /> (Route Change Optional)
                  </p>
                </Grid>
                <Grid item xs={4} style={{ marginTop: 0 }}>
                  <p style={{ fontSize: "0.75rem", cursor: "default" }}>
                    Undesirable <br /> (Route Change Preferred)
                  </p>
                </Grid>
                <Grid item xs={3} style={{ marginTop: 0 }}>
                  <p style={{ fontSize: "0.75rem", cursor: "default" }}>
                    Highly Undesirable (Route Change Highly Preferred)
                  </p>
                </Grid>
                <Grid item xs={2} style={{ marginTop: 0 }}>
                  <p style={{ fontSize: "0.75rem", cursor: "default" }}>
                    No Go (Required)
                  </p>
                </Grid>
              </Grid>
              <p style={{ fontSize: "0.75rem", cursor: "default" }}>
                {railroadTypicalClearance?.["sentence_1"]}{" "}
                {railroadTypicalClearance?.["sentence_2"]}{" "}
                {railroadTypicalClearance?.["sentence3"]}
              </p>
            </div>
          )}
          <div
            style={{
              marginTop: "10px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Tooltip
              placement="right"
              classes={{ tooltip: classes.zoomTooltip }}
              title={
                railVisibility
                  ? "Including this risk factor in calculations"
                  : "Excluding this risk factor from calculations"
              }
              arrow
            >
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch(toggleRailVisibility());
                }}
                style={{
                  pointerEvents: "auto", // Always allow interaction
                  opacity: 1, // Keep button fully visible
                }}
              >
                {railVisibility ? (
                  <VisibilityIcon fontSize="small" />
                ) : (
                  <VisibilityOffIcon fontSize="small" />
                )}
              </IconButton>
            </Tooltip>

            <Button
              color="primary"
              size="small"
              onClick={() => toggleInfo("RailroadTypicalClearanceInfo")}
              disabled={!railVisibility}
            >
              {infoStates.RailroadTypicalClearanceInfo
                ? "Hide Info"
                : "More Info"}
            </Button>
          </div>
        </Box>

        <Box
          pr={3}
          pl={3}
          pt={1}
          mb={1}
          border="1px solid gray"
          style={{
            opacity: tunnelVisibility ? 1 : 0.5,
            pointerEvents: tunnelVisibility ? "auto" : "none",
          }}
        >
          <Typography gutterBottom style={{ display: "inline-block" }}>
            Tunnels (small or large)
          </Typography>
          <TunnelsSmallOrLargeSlider
            onChangeCommitted={changeTunnelSlider}
            track={false}
            defaultValue={tunnelSliderValue}
            aria-labelledby="discrete-slider"
            valueLabelDisplay="auto"
            step={0.1}
            marks={[
              { value: 1, label: "1" },
              { value: 2, label: "2" },
              { value: 3, label: "3" },
              { value: 4, label: "4" },
              { value: 5, label: "5" },
              { value: 5.5 },
              { value: 6, label: "6" },
              { value: 7, label: "7" },
              { value: 8, label: "8" },
              { value: 9, label: "9" },
              { value: 10, label: "10" },
            ]}
            min={1.0}
            max={10.0}
            disabled={!tunnelVisibility}
          />
          {infoStates.TunnelsSmallOrLargeInfo && (
            <div>
              <Grid
                container
                spacing={1}
                direction="row"
                justifyContent="space-evenly"
              >
                <Grid item xs={3} style={{ marginTop: 0 }}>
                  <p style={{ fontSize: "0.75rem", cursor: "default" }}>
                    Acceptable <br /> (Route Change Optional)
                  </p>
                </Grid>
                <Grid item xs={4} style={{ marginTop: 0 }}>
                  <p style={{ fontSize: "0.75rem", cursor: "default" }}>
                    Undesirable <br /> (Route Change Preferred)
                  </p>
                </Grid>
                <Grid item xs={3} style={{ marginTop: 0 }}>
                  <p style={{ fontSize: "0.75rem", cursor: "default" }}>
                    Highly Undesirable (Route Change Highly Preferred)
                  </p>
                </Grid>
                <Grid item xs={2} style={{ marginTop: 0 }}>
                  <p style={{ fontSize: "0.75rem", cursor: "default" }}>
                    No Go (Required)
                  </p>
                </Grid>
              </Grid>
              <p style={{ fontSize: "0.75rem", cursor: "default" }}>
                {tunnelsSmallOrLarge?.["sentence_1"]}{" "}
                {tunnelsSmallOrLarge?.["sentence_2"]}{" "}
                {tunnelsSmallOrLarge?.["sentence3"]}
              </p>
            </div>
          )}
          <div
            style={{
              marginTop: "10px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Tooltip
              placement="right"
              classes={{ tooltip: classes.zoomTooltip }}
              title={
                tunnelVisibility
                  ? "Including this risk factor in calculations"
                  : "Excluding this risk factor from calculations"
              }
              arrow
            >
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch(toggleTunnelVisibility());
                }}
                style={{
                  pointerEvents: "auto", // Always allow interaction
                  opacity: 1, // Keep button fully visible
                }}
              >
                {tunnelVisibility ? (
                  <VisibilityIcon fontSize="small" />
                ) : (
                  <VisibilityOffIcon fontSize="small" />
                )}
              </IconButton>
            </Tooltip>

            <Button
              color="primary"
              size="small"
              onClick={() => toggleInfo("TunnelsSmallOrLargeInfo")}
              disabled={!tunnelVisibility}
            >
              {infoStates.TunnelsSmallOrLargeInfo ? "Hide Info" : "More Info"}
            </Button>
          </div>
        </Box>

        <Box
          pr={3}
          pl={3}
          pt={1}
          mb={1}
          border="1px solid gray"
          style={{
            opacity: curvatureVisibility ? 1 : 0.5,
            pointerEvents: curvatureVisibility ? "auto" : "none",
          }}
        >
          <Typography gutterBottom style={{ display: "inline-block" }}>
            Winding Narrow Roads
          </Typography>
          <CurvatureSlider
            track={false}
            onChangeCommitted={changeCurvatureSlider}
            defaultValue={curvatureSliderValue}
            aria-labelledby="discrete-slider"
            valueLabelDisplay="auto"
            step={0.1}
            marks={[
              { value: 1, label: "1" },
              { value: 2, label: "2" },
              { value: 3, label: "3" },
              { value: 4, label: "4" },
              { value: 5, label: "5" },
              { value: 5.6 },
              { value: 6, label: "6" },
              { value: 7, label: "7" },
              { value: 8, label: "8" },
              { value: 9, label: "9" },
              { value: 10, label: "10" },
            ]}
            min={1.0}
            max={10.0}
            disabled={!curvatureVisibility}
          />
          {infoStates.CurvatureInfo && (
            <div>
              <Grid
                container
                spacing={1}
                direction="row"
                justifyContent="space-evenly"
              >
                <Grid item xs={3} style={{ marginTop: 0 }}>
                  <p style={{ fontSize: "0.75rem", cursor: "default" }}>
                    Acceptable <br /> (Route Change Optional)
                  </p>
                </Grid>
                <Grid item xs={4} style={{ marginTop: 0 }}>
                  <p style={{ fontSize: "0.75rem", cursor: "default" }}>
                    Undesirable <br /> (Route Change Preferred)
                  </p>
                </Grid>
                <Grid item xs={3} style={{ marginTop: 0 }}>
                  <p style={{ fontSize: "0.75rem", cursor: "default" }}>
                    Highly Undesirable (Route Change Highly Preferred)
                  </p>
                </Grid>
                <Grid item xs={2} style={{ marginTop: 0 }}>
                  <p style={{ fontSize: "0.75rem", cursor: "default" }}>
                    No Go (Required)
                  </p>
                </Grid>
              </Grid>
              <p style={{ fontSize: "0.75rem", cursor: "default" }}>
                {curvature?.["sentence_1"]} {curvature?.["sentence_2"]}{" "}
                {curvature?.["sentence3"]}
              </p>
            </div>
          )}
          <div
            style={{
              marginTop: "10px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Tooltip
              placement="right"
              classes={{ tooltip: classes.zoomTooltip }}
              title={
                curvatureVisibility
                  ? "Including this risk factor in calculations"
                  : "Excluding this risk factor from calculations"
              }
              arrow
            >
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch(toggleCurvatureVisibility());
                }}
                style={{
                  pointerEvents: "auto", // Always allow interaction
                  opacity: 1, // Keep button fully visible
                }}
              >
                {curvatureVisibility ? (
                  <VisibilityIcon fontSize="small" />
                ) : (
                  <VisibilityOffIcon fontSize="small" />
                )}
              </IconButton>
            </Tooltip>

            <Button
              color="primary"
              size="small"
              onClick={() => toggleInfo("CurvatureInfo")}
              disabled={!curvatureVisibility}
            >
              {infoStates.CurvatureInfo ? "Hide Info" : "More Info"}
            </Button>
          </div>
        </Box>

        <br></br>
        {/* <Box pr={15} pl={15} pt={1}> */}
        {/* <br></br>
          <br></br>
          <br></br>
          <br></br> */}
        {/* <Typography gutterBottom style={{ display: "inline-block" }}>
            No Cell Coverage
          </Typography>
          <FormControl
            className={classes.dropdown}
            style={{ display: "inline-block" }}
          > */}
        {/*<InputLabel htmlFor="cell-provider">Provider</InputLabel>*/}
        {/* <Select
              native
              id="cell-provider"
              value={dropdownState.provider}
              onChange={handleDropdownChange}
              inputProps={{
                name: "provider",
                id: "cell-provider",
              }}
            > */}
        {/* <option aria-label="None" value="" />
              <option value={"any"}>Any Provider</option>
              <option value={"att"}>AT&T</option>
              <option value={"usc"}>US Cellular</option>
              <option value={"tmo"}>T-Mobile</option>
              <option value={"vez"}>Verizon</option> */}
        {/* </Select> */}
        {/* </FormControl> */}
        {/*</Typography>*/}
        {/* <Slider
            id="cellCoverageSlider"
            defaultValue={cellCoverageSliderValue}
            onChangeCommitted={changeCellCoverageSlider}
            getAriaValueText={valuetext}
            aria-labelledby="discrete-slider"
            valueLabelDisplay="auto"
            step={1}
            marks={marks}
            min={1}
            max={10}
          />
        </Box>
        <Box pr={15} pl={15} pt={1}>
          <Typography gutterBottom>Traffic Incidents</Typography>
          <Slider
            id="incidentsSlider"
            defaultValue={incidentsSliderValue}
            onChangeCommitted={changeIncidentsSlider}
            getAriaValueText={valuetext}
            aria-labelledby="discrete-slider"
            valueLabelDisplay="auto"
            step={1}
            marks={marks}
            min={1}
            max={10}
          />
        </Box>
        <Box pr={15} pl={15} pt={0}>
          <Typography gutterBottom>Population</Typography>
          <Slider
            id="populationSlider"
            defaultValue={populationSliderValue}
            onChangeCommitted={changePopulationSlider}
            getAriaValueText={valuetext}
            aria-labelledby="discrete-slider"
            valueLabelDisplay="auto"
            step={1}
            marks={marks}
            min={1}
            max={10}
          />
        </Box>
        <Box pr={15} pl={15} pt={0} pb={0}>
          <Typography gutterBottom>Rail Crossings</Typography>
          <Slider
            id="railJunctionsSlider"
            defaultValue={railJunctionsSliderValue}
            onChangeCommitted={changeRailJunctionsSlider}
            getAriaValueText={valuetext}
            aria-labelledby="discrete-slider"
            valueLabelDisplay="auto"
            step={1}
            marks={marks}
            min={1}
            max={10}
          />
        </Box>
        <Box pr={15} pl={15} pt={0} pb={0}>
          <Typography gutterBottom>Out of Service Area</Typography>
          <Slider
            id="safeHavensSlider"
            defaultValue={safeHavensSliderValue}
            onChangeCommitted={changeSafeHavensSlider}
            getAriaValueText={valuetext}
            aria-labelledby="discrete-slider"
            valueLabelDisplay="auto"
            step={1}
            marks={marks}
            min={1}
            max={10}
          />
        </Box>
        <Box pr={15} pl={15} pt={0} pb={0}>
          <Typography gutterBottom>Tunnels</Typography>
          <Slider
            id="tunnelsSlider"
            defaultValue={tunnelsSliderValue}
            onChangeCommitted={changeTunnelsSlider}
            getAriaValueText={valuetext}
            aria-labelledby="discrete-slider"
            valueLabelDisplay="auto"
            step={1}
            marks={marks}
            min={1}
            max={10}
          />
        </Box>
        <Box pr={15} pl={15} pt={0} pb={0}>
          <Typography gutterBottom>Weather</Typography>
          <Slider
            id="weatherSlider"
            defaultValue={weatherSliderValue}
            onChangeCommitted={changeWeatherSlider}
            getAriaValueText={valuetext}
            aria-labelledby="discrete-slider"
            valueLabelDisplay="auto"
            step={1}
            marks={marks}
            min={1}
            max={10}
          />
        </Box>
        <Box pr={15} pl={15} pt={0} pb={0}>
          <Typography gutterBottom>Wildfires</Typography>
          <Slider
            id="wildfiresSlider"
            defaultValue={wildfiresSliderValue}
            onChangeCommitted={changeWildfiresSlider}
            getAriaValueText={valuetext}
            aria-labelledby="discrete-slider"
            valueLabelDisplay="auto"
            step={1}
            marks={marks}
            min={1}
            max={10}
          />
        </Box> */}
        <Button
          variant="contained"
          id="runVulBtn"
          color="primary"
          onClick={() => runVulnerabilityCalc()}
        >
          Run Calculator
        </Button>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <TstarTab />
      </TabPanel>
    </div>
  );
}
