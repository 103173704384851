import { StackedBarChartTemplate } from "./ChartTemplates";
import { useAppSelector } from "../../redux/hooks";
import {
  getRoute,
  getRoute2,
  getRoute3,
} from "../../redux/reducers/uploadedRouteSlice";
import { getChart } from "../../redux/reducers/chartDisplaySlice";

const colors = [
  "#4C62C8", //bridge over water
  "#ffa584", //bridge over land
  "#906F51", //bridge overpass/underpass
  "#007c65", //curvature
  "#301934", //population rural
  "#800020", //population suburban
  "#BEBFC5", //population urban
  "#9966CC", //rail crossing
  "#888D3F", //tunnels
];

// From bhaksar, potential additional colors will be used when we expand to additional risk factors
// ["#796799","#87da62","#6267f1","#e28f00","#6d3e8c","#d6c866","#a03300","#007c65","#ffa584","#006e29","#958a55"]
// #0D0B1E, #35173B, #572339, #724631, #888D3F, #5EA64E, #69B594, #86AEC1, #A5A2CD, #D5BEDA, #E8D8DF
// [#6D6917, #438820, #2AA258, #35AEBB, #4C62C8, #9F6ACD, #D487C1, #DBA3A5, #E4D7BE, #E5EED8, #F1F8F2]

const risk_factors = [
  "risk",
  "bridge_overwater",
  "bridge_overland",
  "bridge_overpass",
  "curvature",
  "rural_population",
  "suburban_population",
  "urban_population",
  "rail",
  "tunnel",
];

const risk_factors_match: any = {
  risk: "Risk Category",
  bridge_overwater: "Bridge Over Water",
  bridge_overland: "Bridge Over Land",
  bridge_overpass: "Bridge Overpass/Underpass",
  curvature: "Winding Narrow Roads",
  rural_population: "Population: Rural",
  suburban_population: "Population: Suburban",
  urban_population: "Population: Urban",
  rail: "Rail Crossing",
  tunnel: "Tunnels",
};

const risk_factors_legend = [
  "Risk Category",
  "Bridge Over Water",
  "Bridge Over Land",
  "Bridge Overpass/Underpass",
  "Winding Narrow Roads",
  "Population: Rural",
  "Population: Suburban",
  "Population: Urban",
  "Rail Crossing",
  "Tunnels",
];

interface RISKS {
  risk: string;
  bridge_overwater: string;
  bridge_overland: string;
  bridge_overpass: string;
  curvature: string;
  rural_population: string;
  suburban_population: string;
  urban_population: string;
  rail: string;
  tunnel: string;
}

function RiskStackedBarChart() {
  const loadedRoute = useAppSelector(getRoute);
  const loadedRoute2 = useAppSelector(getRoute2);
  const loadedRoute3 = useAppSelector(getRoute3);
  const chart = useAppSelector(getChart);

  let route;
  switch (chart) {
    case 0:
    case 1:
      route = loadedRoute;
      break;
    case 2:
      route = loadedRoute2;
      break;
    case 3:
      route = loadedRoute3;
      break;
    default:
      route = loadedRoute; // Fallback
  }

  const features: any = [];
  const addedNames = new Set();

  const risk_data = route.features
    .map((d: any, i: number) => {
      const risks: any = {};
      features.push(d);

      function gen(obj: RISKS) {
        for (const [key, value] of Object.entries(obj)) {
          if (key === "explanation") {
            for (const [subKey, subValue] of Object.entries(value)) {
              if (risk_factors.includes(subKey)) {
                risks[risk_factors_match[subKey]] = (subValue as number[])[0];
              }
            }
          } else {
            if (risk_factors.includes(key)) {
              risks[risk_factors_match[key]] = +value;
            }
          }
        }
      }

      gen(d.properties);
      risks["name"] = i.toString();

      if (addedNames.has(risks["name"])) {
        return null; // Skip duplicate names
      }

      addedNames.add(risks["name"]);
      return risks;
    })
    .filter((risks: any) => risks !== null);

  const divStyle: React.CSSProperties = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: "100%",
    overflow: "hidden",
  };

  const chartStyle: React.CSSProperties = {
    height: "350px",
    width: "100%",
    overflow: "hidden",
  };

  return (
    <div style={{ ...divStyle, height: "100%", overflow: "auto" }}>
      <div style={chartStyle}>
        <StackedBarChartTemplate
          data={risk_data}
          xAxis="name"
          yAxis=""
          risks={risk_factors_legend}
          colors={colors}
          features={features}
          lineKey="Risk Category"
        />
      </div>
      <div style={{ marginTop: "10px" }}>
        {/* Any additional content can go here */}
      </div>
    </div>
  );
}

export { RiskStackedBarChart };
