import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sliders: {
    bridge_overpass: 2.2,
    bridge_overwater: 3,
    bridge_overland: 2.3,
    tunnel: 5.5,
    rail: 6,
    curvature: 5.6,
    rural_population: 2,
    suburban_population: 3.5,
    urban_population: 5,
  },
  visibility: {
    bridge_overpass: true,
    bridge_overwater: true,
    bridge_overland: true,
    tunnel: true,
    rail: true,
    curvature: true,
    rural_population: true,
    suburban_population: true,
    urban_population: true,
  },
};

const sliderSlice = createSlice({
  name: "sliders",
  initialState,
  reducers: {
    // Set slider values
    setBridgeOverpassSlider: (state, action) => {
      state.sliders.bridge_overpass = action.payload;
    },
    setBridgeOverWaterSlider: (state, action) => {
      state.sliders.bridge_overwater = action.payload;
    },
    setBridgeOverLandSlider: (state, action) => {
      state.sliders.bridge_overland = action.payload;
    },
    setTunnelSlider: (state, action) => {
      state.sliders.tunnel = action.payload;
    },
    setRailSlider: (state, action) => {
      state.sliders.rail = action.payload;
    },
    setCurvatureSlider: (state, action) => {
      state.sliders.curvature = action.payload;
    },
    setPopulationRuralSlider: (state, action) => {
      state.sliders.rural_population = action.payload;
    },
    setPopulationSuburbanSlider: (state, action) => {
      state.sliders.suburban_population = action.payload;
    },
    setPopulationUrbanSlider: (state, action) => {
      state.sliders.urban_population = action.payload;
    },

    // Toggle visibility
    toggleBridgeOverpassVisibility: (state) => {
      state.visibility.bridge_overpass = !state.visibility.bridge_overpass;
    },
    toggleBridgeOverWaterVisibility: (state) => {
      state.visibility.bridge_overwater = !state.visibility.bridge_overwater;
    },
    toggleBridgeOverLandVisibility: (state) => {
      state.visibility.bridge_overland = !state.visibility.bridge_overland;
    },
    toggleTunnelVisibility: (state) => {
      state.visibility.tunnel = !state.visibility.tunnel;
    },
    toggleRailVisibility: (state) => {
      state.visibility.rail = !state.visibility.rail;
    },
    toggleCurvatureVisibility: (state) => {
      state.visibility.curvature = !state.visibility.curvature;
    },
    togglePopulationRuralVisibility: (state) => {
      state.visibility.rural_population = !state.visibility.rural_population;
    },
    togglePopulationSuburbanVisibility: (state) => {
      state.visibility.suburban_population = !state.visibility.suburban_population;
    },
    togglePopulationUrbanVisibility: (state) => {
      state.visibility.urban_population = !state.visibility.urban_population;
    },
  },
});

export const {
  setBridgeOverpassSlider,
  setBridgeOverWaterSlider,
  setBridgeOverLandSlider,
  setTunnelSlider,
  setRailSlider,
  setCurvatureSlider,
  setPopulationRuralSlider,
  setPopulationSuburbanSlider,
  setPopulationUrbanSlider,
  toggleBridgeOverpassVisibility,
  toggleBridgeOverWaterVisibility,
  toggleBridgeOverLandVisibility,
  toggleTunnelVisibility,
  toggleRailVisibility,
  toggleCurvatureVisibility,
  togglePopulationRuralVisibility,
  togglePopulationSuburbanVisibility,
  togglePopulationUrbanVisibility,
} = sliderSlice.actions;

export default sliderSlice.reducer;

// Selectors for slider values
export const getBridgeOverpassSlider = (state: any) => state.sliders.sliders.bridge_overpass;
export const getBridgeOverWaterSlider = (state: any) => state.sliders.sliders.bridge_overwater;
export const getBridgeOverLandSlider = (state: any) => state.sliders.sliders.bridge_overland;
export const getTunnelSlider = (state: any) => state.sliders.sliders.tunnel;
export const getRailSlider = (state: any) => state.sliders.sliders.rail;
export const getCurvatureSlider = (state: any) => state.sliders.sliders.curvature;
export const getPopulationRuralSlider = (state: any) => state.sliders.sliders.rural_population;
export const getPopulationSuburbanSlider = (state: any) => state.sliders.sliders.suburban_population;
export const getPopulationUrbanSlider = (state: any) => state.sliders.sliders.urban_population;

// Selectors for visibility
export const getBridgeOverpassVisibility = (state: any) => state.sliders.visibility.bridge_overpass;
export const getBridgeOverWaterVisibility = (state: any) => state.sliders.visibility.bridge_overwater;
export const getBridgeOverLandVisibility = (state: any) => state.sliders.visibility.bridge_overland;
export const getTunnelVisibility = (state: any) => state.sliders.visibility.tunnel;
export const getRailVisibility = (state: any) => state.sliders.visibility.rail;
export const getCurvatureVisibility = (state: any) => state.sliders.visibility.curvature;
export const getPopulationRuralVisibility = (state: any) => state.sliders.visibility.rural_population;
export const getPopulationSuburbanVisibility = (state: any) => state.sliders.visibility.suburban_population;
export const getPopulationUrbanVisibility = (state: any) => state.sliders.visibility.urban_population;
